/*
This component will render an active button for booleans
The component will take in  boolean at buttonActive
The chosen option is emited with buttonStateChange this must be bound to an outer varibale
show is used to hide the button.
author: Christopher buggy.
*/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-activation-buttons',
  templateUrl: './activation-buttons.component.html',
  styleUrls: ['./activation-buttons.component.css']
})
export class ActivationButtonsComponent implements OnInit {
  @Input() buttonActive: boolean;
  @Input() buttonOutlineActive: boolean;
  @Input() show = true; // Allows the user to control weather the button is active or not
  @Input() set trueValueText(value) {
    this.trueButtonText = value ? value : 'Active';
  }

  @Input() set falseValueText(value) {
    this.falseButtonText = value ? value : 'Inactive';
  }

  @Input() invertColor = false;
  @Input() trueButtonText = 'Active';
  @Input() falseButtonText = 'Inactive';
  @Input() invertOutlineColor = false;
  @Output() buttonStateChange = new EventEmitter<boolean>();
  @Output() outlineButtonStateChange = new EventEmitter<boolean>();
  buttonText;
  // trueButtonText = 'Active';
  // falseButtonText = 'Inactive';

  constructor() {
  }

  ngOnInit() {
    // console.log(this.buttonActive);
  }

  buttonAssignmentBooleanValue() {
    if (this.buttonActive) {
      this.buttonText = this.trueButtonText;
      return this.invertColor ? 'btn btn-inactive btn-block col-12' : 'btn btn-active btn-block col-12';
    } else if (!this.buttonActive) {
      this.buttonText = this.falseButtonText;
      return this.invertColor ? 'btn btn-active btn-block col-12' : 'btn btn-inactive btn-block col-12';
    } else if (this.buttonOutlineActive) {
      this.buttonText = this.trueButtonText;
      return this.invertOutlineColor ? 'btn btn-outline-danger btn-block col-12' : 'btn btn-outline-success btn-block col-12';
    } else if (!this.buttonOutlineActive) {
      this.buttonText = this.falseButtonText;
      return this.invertOutlineColor ? 'btn btn-outline-success btn-block col-12' : 'btn btn-outline-danger btn-block col-12';
    }
  }

  changeBooleanValue() {
    this.buttonActive = !this.buttonActive;
    this.buttonStateChange.emit(this.buttonActive);
    this.buttonOutlineActive = !this.buttonOutlineActive;
    this.outlineButtonStateChange.emit(this.buttonOutlineActive);
  }

}
