import { Injectable } from '@angular/core';
import {AuthService} from './auth/auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {GetApiReply} from './models/get-api-reply.model';
import {BooleanFilterRestTable} from './models/other/boolean-filter-rest.model';
import {RestTableFilterModel} from './models/other/rest-table-filter-model';
import {catchError, tap} from 'rxjs/operators';
import {ConfigService} from './services/config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private authS: AuthService,
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Replace with logging service later.
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }

  getDataFromDbBySearch(
    targetTable: string,
    valueToTarget: string,
    sortBy: string,
    columns: string,
    value: string,
    checkIsActive: boolean,
    specialCondition?: string
  ): Observable<GetApiReply> {
    let include = ``;
    let requestValue = '';
    let isActive = '';
    if (specialCondition.length > 0) {
      specialCondition = `&${specialCondition}`;
    }
    if (value.length > 0) {
      requestValue = `${valueToTarget}=${value}*&`;
    }
    if (checkIsActive) {
      isActive = '&is_active=TRUE';
    }
    if (columns !== '') {
      include = `&$include_columns=[${columns}]`;
    }
    const httpHeaders = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getOldServerAddress() + targetTable}?${requestValue}$sort=${valueToTarget}${include}${specialCondition}${isActive}` +
      `&$page=1&$per_page=10`,
      httpHeaders
    ).pipe(
      tap(res => {
        return res;
      })
    );
  }
  getDataBySearch(
    targetTable: string,
    valueToTarget: string,
    sortBy: string,
    columns: string,
    value: string,
    checkIsActive: boolean,
    specialCondition?: string
  ): Observable<GetApiReply> {
    let include = ``;
    let requestValue = '';
    let isActive = '';
    if (specialCondition.length > 0) {
      specialCondition = `&${specialCondition}`;
    }
    if (value.length > 0) {
      requestValue = `${valueToTarget}=ilike.*${value}*`;
    }
    if (checkIsActive) {
      isActive = '&is_active=eq.true';
    }
    if (columns !== '') {
      include = `&select=${columns}`;
    }
    const httpHeaders = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress() + targetTable}?${requestValue}&order=${valueToTarget}${include}${specialCondition}${isActive}` +
      // `&page=1&per_page=10`,
    `&limit=10&offset=0`,
      this.conf.getHttpOptions()
    ).pipe(
      tap(res => {
        return res;
      })
    );
  }


  getViewListData(targetTable: string, include: string, sortBy: string) {
    const httpHeaders = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress() + targetTable}?$sort=${sortBy}&$include_columns=[${include}]`,
      httpHeaders
    );
  }
}
