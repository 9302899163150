import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CoreService} from '../../core.service';
import {RestTableFilterModel} from '../../models/rest-table-filter-model';
import {TableItemModel} from '../../models/table-item.model';
import {BsDatepickerConfig, BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import PrintJob from 'print-job';
import {BooleanFilterRestTable} from '../../models/boolean-filter-rest.model';
import {interval} from 'rxjs';

@Component({
  selector: 'app-rest-table',
  templateUrl: './rest-table.component.html',
  styleUrls: ['./rest-table.component.css']
})
/*
@author chris buggy
This component allows for quickly building a restful table that will self write out in the users
browser.
target Table is table in the db you want to target.
columns are your filter options these can be set to be restful filters that
load based on foreign keys.
*/
export class RestTableComponent implements OnInit, OnDestroy {
  @ViewChild('listToPrintOne', {static: false, read: ElementRef}) listToPrintOne: ElementRef;
  @ViewChild('listToPrintTwo', {static: false, read: ElementRef}) listToPrintTwo: ElementRef;
  @Input() targetTable: string;
  @Input() columns: RestTableFilterModel[];
  @Input() includeColumns: string;
  @Input() itemsPerPage: number;
  @Input() sortBy: string;
  // @Input() set _specialCondition (specialCondition: string) {
  //   this.specialCondition = specialCondition;
  //   x
  // }

  @Input() waitToLoad = false;

  @Input() set _nullFilters(nullFilters: BooleanFilterRestTable[]) {
    this.nullFilters = nullFilters;
  }

  @Input() set _dateRange(dateRange: boolean) {
    this.dateRange = dateRange;
  }

  // If you filter from a date range or one date
  @Input() set _dateFiltersGreaterThen(dateFiltersGreaterThen: string) {
    this.dateFiltersGreaterThen = dateFiltersGreaterThen;
  } // what you want the the data filter to be based on e.g transaction of effective from
  @Input() set _dateFilterLessThen(dateFilterLessThen: string) {
    this.dateFilterLessThen = dateFilterLessThen;
  }

  // Sets weather the table is printable. it uses printjob libary
  @Input() set _printable(printable: boolean) {
    this.printable = printable;
  }

  // A Sepcial condition is a string added to the end of load table
  // this will allow to add any conditions you want from outside factors.
  @Input() set _specialConditions(specialConditions: string) {
    this.specialConditions = specialConditions;
    if (this.finishedSetUpTime) {
      this.loadTable();
      console.log('special condititions');
    }
  }

  // A row of buttons across the top of the table
  // These buttons emit a value using the customButtonEmit
  // output. They Emit the selected items.
  @Input() set _topButtons(topButtons: string []) {
    this.topButtons = topButtons;
  }

  // Same as a top buttons just run across the bottom.
  @Input() set _bottomButtons(bottomButtons: string []) {
    this.bottomButtons = bottomButtons;
  }

  // Limits the selection to just one item.
  @Input() set _limitSelection(limitSelection: boolean) {
    this.limitSelection = limitSelection;
  }

  // Will state weather a date filter should be shown.
  @Input() set _hasDateFilter(hasDateFilter: boolean) {
    this.hasDateFilter = hasDateFilter;
  }

  // Adds the name to the crud option button and used for headings
  @Input() set _nameOnLabels(nameOnLabels: string) {
    this.nameOnLabels = nameOnLabels;
  }

  // will emit the users selection choice if set to true.
  @Input() set _emitObject(val: boolean) {
    this.emitObject = val;
  }

  // controls if the select all buttons should be shown.
  @Input() set _emitSelectAllList(val: boolean) {
    this.emitSelectAll = val;
  }

  // a set of filters that target boolean values in tables.
  @Input() set _booleanFilters(filters: BooleanFilterRestTable[]) {
    this.booleanFilters = filters.slice(0);
  }

  // set to false to hide the create, edit and delete buttons.
  @Input() set _crudOptions(crudOn: boolean) {
    this.showCrud = crudOn;
  }

  // used to deal with the table values see the tableItemMode.
  // each object will appear in the table.
  @Input() set _tableItems(tableItems: TableItemModel[]) {
    this.tableItems = tableItems;
  }

  // Will fire off a load table event.
  @Input() set _reload(event: boolean) {
    if (this.finishedSetUpTime) {
      this.loadTable();
      console.log('reload');
    }
  }

  // a preSelected item from the table.
  @Input() set preSelect(ps) {
    if (ps !== null) {
      this.selectedItems.push(ps);
    }
  }

  // controls what column the date filter targets.
  @Input() set _dateTarget(dbColumnForDateFilter: string) {
    this.dbColumnForDateFilter = dbColumnForDateFilter;
  }

  // Controls interval of the table. if set to through the table filter method will be
  // called by itself at intervals.
  @Input()
  liveReload = false;
  pageNumber = 1; // the current page of the pagnation.
  maxPageNumber = 1; // how many pages are a avialbe the api will give this value to you in responses.
  dbColumnForDateFilter = ''; // Varfor input setting
  specialCondition = ''; // Varfor input setting
  // i = 0; no longer used
  nullFilters = []; // null filters
  pageIndex = 1; // What page the user is currently on.
  selectedItems = []; // array of the selected Items.
  tableItems = []; // var for input setting
  booleanFilters = []; // var for input setting
  printable = false; // var for weather the table is printable
  nameOnLabels; // names on labels
  loading = false; // controls weather to show svg spinning icon.
  showCrud = true; // controls weather to show CRUD buttons.
  dateRange = false; // controls if the date filter is a date range
  limitSelection = false; // controls if you can select more then on item.
  lastFilter = Date.now() - 2000; // last filter is to stop typing from causing a mass amount of calls to the api.
  baseData = []; // holds the data for the table.
  dateFiltersGreaterThen; // What date column to target.
  dateFilterLessThen; // same as above but for less then
  hasDateFilter = false; // states if date filtering is avalile
  topButtons = []; // used to store top buttons
  bottomButtons = []; // used to store bottom buttons
  fromDate = ''; // date holder
  toDate = ''; // date holder
  specialConditions = ''; // special condition for api calls.
  printing = false; // value to create a second header to show in printing.
  emitObject = false; // it will set wheater the table emits the object again if already selected or a null value
  emitSelectAll = false; // this will emit a list of objects on select all if set to through.
  bsConfigRange: Partial<BsDaterangepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'}); // sets style for date picker
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'}); // sets style for date picker
  subscription;
  finishedSetUpTime = false;
  // Outputs are used through methods
  @Output() itemChosen = new EventEmitter<any>();
  @Output() deleteItems = new EventEmitter<any>();
  @Output() createItem = new EventEmitter<any>();
  @Output() editItems = new EventEmitter<any>();
  @Output() customButtonEmit = new EventEmitter<any>();
  @Output() newSelectedItem = new EventEmitter<any>();
  @Output() emitListEvent = new EventEmitter<any>();

  constructor(
    private coreS: CoreService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.finishedSetUpTime = true;
    }, 1000);
    this.lastFilter = 0;
    if (!this.waitToLoad) {
      this.loadTable();
    }
    this.createTableInterval();
  }

  /*
  Method used for the live reloading of the table.
  */
  createTableInterval() {
    if (this.liveReload) {
      const secondsCounter = interval(300000);
      this.subscription = secondsCounter.subscribe(() => {
        this.loadTable();
      });
    }
  }

  /*
  Controls the page of the pagnation filtering
  from the api. it will limit it to a range of 1 to the count max amount
  */
  setPageIndex() {
    if (this.pageNumber !== null && this.pageNumber !== 0) {
      if (this.pageNumber <= this.maxPageNumber) {
        this.pageIndex = this.pageNumber;
      } else {
        this.pageNumber = this.maxPageNumber;
        this.pageIndex = this.maxPageNumber;
      }
      if (this.finishedSetUpTime) {
        this.loadTable();
      }
    }
  }

  // adds to page number and calls the setPageIndex method
  plusOneIndex() {
    if (this.pageNumber !== this.maxPageNumber) {
      this.pageNumber++;
      this.setPageIndex();
    }
  }

  // minus from page number
  minusOneIndex() {
    if (this.pageNumber !== 1) {
      this.pageNumber--;
      this.setPageIndex();
    }
  }

  // clears the filters of the user inputs.
  clearFilters() {
    this.columns.forEach(c => {
      c.userInput = '';
    });
    if (this.finishedSetUpTime) {
      this.loadTable();
    }
  }

  selectAll() {
    this.selectedItems = this.baseData.slice(0);
    this.emitListEvent.emit(this.baseData.slice(0));
  }

  unSelectAll() {
    this.selectedItems.splice(0, this.selectedItems.length);
    this.emitListEvent.emit([]);
  }

  booleanFilterValueChange(booleanFilter, booleanValue) {
    booleanFilter.boolValue = booleanValue;
    if (this.finishedSetUpTime) {
      this.loadTable();
    }
  }

  selectItem(item) {
    console.log(item);
    if (this.limitSelection) {
      if (item !== null) {
        this.selectedItems = [item];
        if (this.emitObject) {
          this.newSelectedItem.emit(item);
        }
      }
    } else {
      const indexOfSelectedItem = this.selectedItems.findIndex(alreadySelectedItem => alreadySelectedItem.id === item.id);
      if (indexOfSelectedItem === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(indexOfSelectedItem, 1);
      }
      this.newSelectedItem.emit(item);
    }
    console.log(this.selectedItems);
  }

  filterForignKeyTable(event, column) {
    if (event !== null) {
      column.userInput = event.id;
    } else {
      column.userInput = '';
    }
    console.log('Filtering ForingKey');
    if (this.finishedSetUpTime) {
      this.loadTable();
    }
  }

  loadTable() {
    console.log(`Loading from ${this.targetTable}`);
    this.loading = true;
    if (this.lastFilter + 500 < Date.now()) {
      // this.columns.forEach(col => {
      //   // if (col.userInput !== '') {
      //   //   this.i = 0;
      //   //   this.pageIndex = 1;
      //   // }
      // });
      this.coreS.getTableDataFromDbBySearch(this.targetTable, this.sortBy, this.columns,
        this.includeColumns, this.itemsPerPage, this.fromDate, this.toDate, this.dbColumnForDateFilter,
        this.specialConditions, this.pageIndex, this.booleanFilters, this.nullFilters
      ).subscribe(res => {
        this.baseData = res.data;
        this.maxPageNumber = res.metadata.total_pages;
        this.loading = false;
      });
      this.lastFilter = Date.now();
    } else {
      this.loading = false;
    }
  }


  onCreate() {
    this.selectedItems = [];
    this.createItem.emit(true);
  }

  onEdit() {
    this.editItems.emit(this.selectedItems);
    this.selectedItems = [];
  }

  onDelete() {
    this.deleteItems.emit(this.selectedItems);
    this.selectedItems = [];
  }

  alreadySelected(tableItem) {
    const myIndex = this.selectedItems.findIndex(si => si.id === tableItem.id);
    if (myIndex !== -1) {
      return ['#53A451', 'white'];
    } else {
      return ['', ''];
    }
  }

  applyDates(dateRange) {
    this.fromDate = dateRange[0];
    this.toDate = dateRange[1];
    if (this.finishedSetUpTime) {
      this.loadTable();
    }
    console.log('dates');
  }

  applyOneDate(theDate) {
    this.fromDate = theDate;
    this.toDate = theDate;
    if (this.finishedSetUpTime) {
      this.loadTable();
    }
    console.log('date');
  }

  buttonEmit(name) {
    this.customButtonEmit.emit({name: name, list: [...this.selectedItems]});
    this.selectedItems = [];
  }

  activateCrudApps() {
    if (this.selectedItems.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  sortTableFromIt(item) {
    if (item.multipleObject) {
      this.baseData.sort(
        (a, b) => a[item.valuesToAccess][item.multipleObjectValue] < b[item.valuesToAccess][item.multipleObjectValue] ? -1 : 1
      );
      this.baseData.sort(
        (a, b) => a[item.valuesToAccess][item.multipleObjectValue] < b[item.valuesToAccess][item.multipleObjectValue] ? -1 : 1
      );
    } else {
      this.baseData.sort(
        (a, b) => a[item.valuesToAccess] < b[item.valuesToAccess] ? -1 : 1
      );
      this.baseData.sort(
        (a, b) => a[item.valuesToAccess] < b[item.valuesToAccess] ? -1 : 1
      );
    }
    // console.log(this.filteredData[0][columnToUse]);
    // this.filteredData.sort((a, b) => a[columnToUse] < b[columnToUse] ? -1 : 1 );
    // this.baseData.sort((a, b) => a[columnToUse] < b[columnToUse] ? -1 : 1 );
  }

  printList() {
    this.printing = true;
    setTimeout(() => PrintJob.print(this.listToPrintTwo.nativeElement), 1000);
    setTimeout(() => this.printing = false, 1000);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    console.log('Component Destroyed');
    if (this.liveReload) {
      this.subscription.unsubscribe();
    }
  }

}
