import {Injectable} from '@angular/core';
import {GetApiReply} from '../../../models/get-api-reply.model';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SalestrackApiConfService} from '../salestrackApi-conf.service';

@Injectable()
export class UserServiceV2 {
  constructor(
    private http: HttpClient,
    private configService: SalestrackApiConfService
  ) {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Replace with logging service later.
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token')
      }),
      observe: 'response' as 'body'
    };
  }

  get(optionalParams = '', pageIndex = null) {
    let httpOptions = this.getHttpOptions();
    if (pageIndex && pageIndex === 1) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
          'Prefer': 'count=exact'
        }),
        observe: 'response' as 'body'
      };
    }
    return this.http.get<GetApiReply>(
      `${this.configService.getServerAddress()}users?${optionalParams}`,
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      }),
    );
  }
}
