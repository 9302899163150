import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ErrorMessageService} from './error-message.service';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-error-messages',
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.css']
})
export class ErrorMessagesComponent implements OnInit, OnDestroy {
  @Input() errorMessage: string;
  showError = false;
  subscription: Subscription;

  errorMessages = [];

  dismissible = true;
  alerts = [];

  constructor(
    private errorS: ErrorMessageService,
    private alertS: AlertService,
  ) {
    this.subscription = errorS.errorMessageSource$.subscribe(
      error => {
        this.errorMessages.push(error);
        if (this.showError === false) {
          this.showError = true;
        }
      }
    );
    this.subscription = alertS.errorMessageSource$.subscribe(
      error => {
        console.log('error', error);
        this.alerts.push(error);
      }
    );
  }

  ngOnInit() {
  }

  removeMessage(i) {
    this.errorMessages.splice(i, 1);
    if (this.errorMessages.length === 0) {
      this.showError = false;
    }
  }

  closeErrors() {
    this.errorMessages = [];
    this.showError = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset(): void {
    this.alerts = [];
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  clear() {
    this.alerts = [];
  }

}
