import {Injectable} from '@angular/core';
import {GetApiReply} from '../../../models/get-api-reply.model';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SalestrackApiConfService} from '../salestrackApi-conf.service';

@Injectable()
export class UserPermissionsServiceV2 {
  constructor(
    private http: HttpClient,
    private configService: SalestrackApiConfService
  ) {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Replace with logging service later.
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token')
      }),
      observe: 'response' as 'body'
    };
  }

  create(new_user_permissions) {
    const httpOptions = this.getHttpOptions()
    return this.http.post<GetApiReply>(
      `${this.configService.getServerAddress()}user_permissions`,
      new_user_permissions,
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
        };
      }),
    );
  }

  update(id, new_user_permissions) {
    const httpOptions = this.getHttpOptions()
    return this.http.patch<GetApiReply>(
      `${this.configService.getServerAddress()}user_permissions?id=eq.${id}`,
      new_user_permissions,
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
        };
      }),
    );
  }
}
