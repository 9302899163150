import {Injectable} from '@angular/core';
import {GetApiReply} from '../models/get-api-reply.model';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {PostPutDelApiReply} from '../models/post-put-delete-api-reply.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  getProducts(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}products?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  // Stock

  getStockLocations(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}locations?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  // Locations

  getLocations(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}locations?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createLocation(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}locations`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateLocation(item, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}locations?id=eq.${id}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  // Location Types
  getLocationTypes(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}location_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createLocationType(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}location_types`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateLocationType(item, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}location_types?id=eq.${id}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  // Storage Area
  getStorageAreas(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}storage_areas?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createStorageArea(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}storage_areas`,
      item,
      this.conf.getHttpOptions()
    );
  }

  createProductStorageArea(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_storage_areas`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateStorageArea(item, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}storage_areas?id=eq.${id}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  // Storage Area Types
  getStorageAreaTypes(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}storage_area_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createStorageAreaType(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}storage_area_types`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateStorageAreaType(item, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}storage_area_types?id=eq.${id}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  getStockDiscrepancies(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}stock_discrepancies?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getProductStorageAreas(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}product_storage_areas?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  updateProductStorageAreaItem(updated_item, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_storage_areas?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  createProductStorageAreas(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_storage_areas`,
      item,
      this.conf.getHttpOptions()
    );
  }
}
