import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';
import {GetApiReply} from '../models/get-api-reply.model';
import {PostPutDelApiReply} from '../models/post-put-delete-api-reply.model';
import {Frequencytype} from '../models/dbModels/frequencytype.model';
import {catchError, tap} from 'rxjs/operators';
import {BillingReference} from '../models/dbModels/billing-reference.model';
import {Vatrate} from '../models/dbModels/vatrate.model';
import roundHalfEven from 'round-half-even';
import {Observable, of} from 'rxjs';
import {SavedReport} from '../models/dbModels/savedReport.model';

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  reportToBeSaved: SavedReport;
  customerToBeSaved = '';
  customerCategoryToBeSaved = '';
  productToBeSaved = '';
  productCategoryToBeSaved = '';
  routeToBeSaved = '';

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  getAgents(optionalParams = '') {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?${optionalParams}`,
      // @ts-ignore
      httpOptions
    );
  }


  // uses the round-half-even npm library to achieve bankers rounding.
  // can be found at https://www.npmjs.com/package/round-half-even
  roundNumber(number: number, decimalPlaces: number) {
    if (number === null) {
      number = 0;
    }
    const minus = number < 0 ? true : false;
    number = minus ? number * -1 : number;
    let returnNumber = roundHalfEven(number, decimalPlaces);
    returnNumber = minus ? returnNumber * -1 : returnNumber;
    console.log(returnNumber);
    return returnNumber;
  }

  /** Agent Commissions */

  // Commissions

  getAgentCommissions(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getAgentProductsCommission(agentId: number, productIds: string) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agent_products?delivery_agent_id=eq.${agentId}&product_id=in.(${productIds})`,
      this.conf.getHttpOptions()
    );
  }

  createAgentCommission(item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateCommission(commission) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_commissions?id=eq.${commission.id}`,
      commission,
      this.conf.getHttpOptions()
    );
  }

  updateAgentCommission() {
  }

  deleteCommission(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_commissions?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  deleteAgentCommission() {
  }

  getCommissionsForTransactionsBasedOnProductIds(agentId: number, productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/commission_percentages?product_id=[${productIds}]&customer_id=${customerId}` +
      `&delivery_agent_id=${agentId}&$exclude_columns=nested`,
      this.conf.getHttpOptions()
    );
  }

  // Agents

  createAgent(new_item) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents`,
      new_item,
      // @ts-ignore
      httpOptions
    );
  }

  updateAgent(updated_item, id) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}delivery_agents?id=eq.${id}`,
      updated_item,
      // @ts-ignore
      httpOptions
    );
  }

  /** Banks */

  // Banks
  getBanks(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}banks?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBank(newBank) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}banks`,
      newBank,
      this.conf.getHttpOptions(),
    );
  }

  updateBank(bankUpdate, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}banks?id=eq.${id}`,
      bankUpdate,
      this.conf.getHttpOptions(),
    );
  }

  // Bank Accounts
  getBankAccounts(optionalParams = '') {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}bank_accounts?${optionalParams}`,
      httpOptions
    );
  }

  createBankAccount(newAccount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bank_accounts`,
      newAccount,
      this.conf.getHttpOptions(),
    );
  }

  updateBankAccount(updatedAccount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bank_accounts?id=eq.${id}`,
      updatedAccount,
      this.conf.getHttpOptions(),
    );
  }

  deleteBankAccount(id: number) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}bank_accounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  getCurrencies(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}currencies?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  /** Billing */

  // Billings

  getBillings(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billings?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBillings(provisional_billings) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/create_billings`,
      provisional_billings,
      this.conf.getSpHttpOptions()
    );
  }

  updateBillings(updated_billing, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${id}`,
      updated_billing,
      this.conf.getHttpOptions()
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      })
    );
  }

  deleteBillings(id) {
    if (isNaN(id)) {
      return null;
    }
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${id}`,
      this.conf.getHttpOptions()
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      })
    );
  }

  // Billing Details

  getBillingDetails(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_references?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  updateBillingDetails(billing: BillingReference[]) {
    return this.http.patch<PostPutDelApiReply>(
      // @ts-ignore
      `${this.conf.getServerAddress()}billing_references?id=eq.${billing.id}`,
      billing,
      this.conf.getHttpOptions()
    );
  }

  // Billing references

  getBillingReferences(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_references?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBillingReference(billing: BillingReference[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_references`,
      billing,
      this.conf.getHttpOptions()
    );
  }

  updateBillingReference(updated_billing, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}billing_references?id=eq.${id}`,
      updated_billing,
      this.conf.getHttpOptions()
    );
  }

  // Billing types

  getBillingTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  // Provisional billings

  getProvisionalBillings(params, optionalParams) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/provisional_billings?${optionalParams}`,
      params,
      this.conf.getSpHttpOptions()
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      })
    );

  }

  createProvisionalBillings(params, optionalParams) {
    // if (docket_ids !== null) {
    //   docket_ids = docket_ids.filter(function(item) {
    //     return !isNaN(item);
    //   });
    // }
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/provisional_billings?${optionalParams}`,
      params,
      this.conf.getSpHttpOptions()
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      })
    );
  }

  /** Billing Frequencies */

  getBillingFrequencies(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getBillingFrequencyTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billing_frequency_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBillingFrequency(freq) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies`,
      freq,
      this.conf.getHttpOptions()
    );
  }

  updateBillingFrequency(freq: Frequencytype) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies?id=eq.${freq.id}`,
      freq,
      this.conf.getHttpOptions()
    );
  }

  deleteBillingFrequency(id: number) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billing_frequencies?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Countries */

  getCountries(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}countries?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createCountries(new_product) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}countries`,
      new_product,
      this.conf.getHttpOptions()
    );
  }

  updateCountries(updated_product, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}countries?id=eq.${id}`,
      updated_product,
      this.conf.getHttpOptions()
    );
  }

  deleteCountries(id) {
    if (isNaN(id)) {
      return null;
    }
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}countries?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  /** Customer Specific Prices */
  getCustomerProducts(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_products?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createCustomerProduct(CustomerProduct) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_products`,
      [CustomerProduct],
      this.conf.getHttpOptions()
    );
  }

  updateCustomerProduct(CustomerProduct, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_products?id=eq.${id}`,
      CustomerProduct,
      this.conf.getHttpOptions()
    );
  }

  /** Discounts*/

  // Customer
  createCustomerDiscount(discount) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_discounts`,
      discount,
      this.conf.getHttpOptions()
    );

  }

  updateCustomerDiscount(discount, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_discounts?id=eq.${id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteCustomerDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}customer_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  getDocketDiscounts(customerId: number, productId: number): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/docket_discounts?customer_id=eq.${customerId}&product_id=eq.${productId}`,
      this.conf.getHttpOptions()
    );
  }

  getDiscounts(customerId: number, productId: number): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/docket_discounts?customer_id=${customerId}&product_id=${productId}`,
      this.conf.getHttpOptions()
    );
  }

  getCustomerDiscounts(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_discounts?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getCustomerSpecialPrices(customerId): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}customer_products?customer_id=eq.${customerId}`,
      this.conf.getHttpOptions()
    );
  }

  getProductDiscounts(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}product_discounts?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  updateProductDiscount(discount) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}product_discounts?id=eq.${discount.id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  getBuyXGetYFreeDiscounts(date) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}buy_x_get_y_discounts?effective_to=gte.${date}&effective_from=lte.${date}`,
      this.conf.getHttpOptions()
    );
  }

  /** Exports */

  getExports(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}billings?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  setExportedDate(ids, date) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${ids}`,
      {export_date: date},
      this.conf.getHttpOptions()
    );
  }

  setExportedDateToNull(ids) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}billings?id=eq.${ids}`,
      {export_date: null},
      this.conf.getHttpOptions()
    );
  }

  /** Invoices */

  getJsonInvoices(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_json_invoices`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  getRemittanceAdvice(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_json_remittances`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Payments */
  getPayments(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}payments?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getPaymentTypes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}payment_types?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  updatePayments(updated_product, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}payments?id=eq.${id}`,
      updated_product,
      this.conf.getHttpOptions()
    );
  }

  /** Remittances */

  // getProvisionalRemittances

  getProvisionalRemittances(params) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/provisional_remittances`,
      params,
      this.conf.getSpHttpOptions()
      // this.conf.getHttpOptions()
    );
  }

  // createProvisionalRemittances
  createProvisionalRemittances(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/provisional_remittances`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  createRemittances(provisional_billings) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/create_remittances`,
      provisional_billings,
      this.conf.getSpHttpOptions()
    );
  }

  // createProvisionalRemittances
  getRemittanceStatements(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_json_remittance_statements`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Reports */
  // Sales Reports
  getReportData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/transaction_reports`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  getStockReportData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/batch_reports`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  getSavedReports(optionalParams: string, pageIndex: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}saved_reports?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  /** Statements */
  getJsonStatements(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/get_json_statements`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Transactions */
  // Discounts
  updateDiscount(discount) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_discounts?id=eq.${discount.id}`,
      discount,
      this.conf.getHttpOptions()
    );
  }

  deleteDiscount(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transaction_discounts?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  // Transactions

  createTransaction(transactions) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}rpc/docket_creation`,
      {'dockets': transactions},
      this.conf.getSpHttpOptions()
    );
  }

  updateTransaction(transaction) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}transactions?id=eq.${transaction.id}`,
      transaction,
      this.conf.getHttpOptions()
    );
  }

  getReturnsForTransactionsBasedOnProductIds(productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/returns_percentages?product_id=[${productIds}]&customer_id=${customerId}`,
      this.conf.getHttpOptions()
    );
  }

  getReturnsForTransactionsBasedOnProductIdsNew(productIds: string, customerId: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}financials/returns_percentages?product_id=in.(${productIds})&customer_id=eq.${customerId}`,
      this.conf.getHttpOptions()
    );
  }

  /** Vat Rates */

  getVatRates(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}vat_rates?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createVatRate(vatRate: Vatrate[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}vat_rates`,
      vatRate,
      this.conf.getHttpOptions()
    );
  }

  updateVatRate(vatRate, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}vat_rates?id=eq.${id}`,
      vatRate,
      this.conf.getHttpOptions()
    );
  }

  /** Old API Stuff */

  getBanksOld(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}banks?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getCurrenciesOld() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}currencies`,
      this.conf.getHttpOptions()
    );
  }

  // Vat Rates

  getVatRates_old() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}vat_rates`,
      this.conf.getHttpOptions()
    );
  }

  getDashboardDataOld(optionalParams: string) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}performance_transaction_dashboards?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getDashboardData(payload) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}rpc/dashboard`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }

  /** Payments */

  deletePayments(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}payments?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  assignPaymentToInvoice(assignment: { billing_id: any; payment_id: any }[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}financials/payments_to_invoices`,
      assignment,
      this.conf.getHttpOptions()
    );
  }

  assignPaymentToBilling(assignment) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}payments_to_billings`,
      assignment,
      this.conf.getHttpOptions()
    );
  }

  assignPaymentToCreditNote(assignment: { payment_id: any; credit_note_billing_id: any }[]) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}financials/payments_to_credit_notes`,
      assignment,
      this.conf.getHttpOptions()
    );
  }

  setSavedReport(report, customer, cusCategory, product, prodCategory, route) {
    this.reportToBeSaved = report;
    this.customerToBeSaved = customer;
    this.customerCategoryToBeSaved = cusCategory;
    this.productToBeSaved = product;
    this.productCategoryToBeSaved = prodCategory;
    this.routeToBeSaved = route;
  }

  getSavedReport() {
    return {
      report: this.reportToBeSaved,
      customer: this.customerToBeSaved,
      cusCategory: this.customerCategoryToBeSaved,
      product: this.productToBeSaved,
      prodCategory: this.productCategoryToBeSaved,
      route: this.routeToBeSaved
    };
  }

  createSavedReports(report) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}saved_reports`,
      [report],
      this.conf.getHttpOptions()
    );
  }

  saveReport(payload) {
    return this.http.post(
      `${this.conf.getServerAddress()}rpc/transaction_reports`,
      payload,
      this.conf.getSpHttpOptions()
    );
  }
}
