import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AuthService} from './core/auth/auth.service';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
// Common Components
import {NavigationBarComponent} from './core/navigation-bar/navigation-bar.component';
// Plugins
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoginAlertComponent} from './login-alert/login-alert.component';
import {ChartsModule, ThemeService} from 'ng2-charts';
import {CoreModule} from './core/core.module';
import {SortableModule} from 'ngx-bootstrap/sortable';
import {AuthModule} from './core/auth/auth.module';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import { PrettyjsoncPipe } from './prettyjsonc.pipe';
import { PrettyjsonPipe } from './prettyjson.pipe';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorModule, MatProgressBarModule, MatRippleModule,
  MatTableModule,
  MatToolbarModule,
  MatCardModule, MatNativeDateModule, MatInputModule, MatAutocompleteModule, MatSnackBarModule, MatDialogModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TestingModule} from './testing/testing.module';
import {UserPermissionsServiceV2} from './core/services/salestrackServiceV2/crud-endpoints/user_permissions.service';
import {UserServiceV2} from './core/services/salestrackServiceV2/crud-endpoints/users.service';
import {UserPermissionController} from './core/controllers/userPermissionsController';
import {LoggerModule, NGXLogger, NGXLoggerHttpService, NgxLoggerLevel, NGXMapperService} from 'ngx-logger';
import {NgxPrintModule} from 'ngx-print';
import {AlertComponent, AlertConfig, AlertModule} from 'ngx-bootstrap/alert';
import {QRCodeModule} from 'angular2-qrcode';
import {DatepickerModule, BsDatepickerModule, BsDaterangepickerConfig, BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DndDirective} from './core/directives/dnd.directive';

@NgModule({
  declarations: [
    AppComponent,
    NavigationBarComponent,
    DashboardComponent,
    LoginAlertComponent,
    PageNotFoundComponent,
    AppComponent,
    PrettyjsoncPipe,
    PrettyjsonPipe,
    // ProductionStockDialogComponent
  ],
  imports: [
    AlertModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AuthModule,
    HttpClientModule,
    FontAwesomeModule,
    AppRoutingModule,
    CoreModule,
    NgMultiSelectDropDownModule.forRoot(),
    ProgressbarModule.forRoot(),
    ChartsModule,
    SortableModule.forRoot(),
    BsDropdownModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatRippleModule,
    MatCardModule,
    MatNativeDateModule,
    TestingModule,
    MatInputModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgxPrintModule,
    LoggerModule.forRoot({
      // serverLoggingUrl: 'https://logs.logdna.com/logs/ingest?hostname=:hostname&mac=:mac&ip=:ip&now=:now',
      // level: NgxLoggerLevel.DEBUG,
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: true,
      disableConsoleLogging: false,
      timestampFormat: 'medium'
    }),
    QRCodeModule,
    MatDialogModule,
    TooltipModule.forRoot()
    // ZXingScannerModule
  ],
  providers: [
    AuthService,
    AlertConfig,
    BsDatepickerConfig,
    BsDaterangepickerConfig,
    {provide: LOCALE_ID, useValue: 'en-ie'},
    NGXLogger,
    NGXMapperService,
    NGXLoggerHttpService,
    ThemeService,
    UserPermissionController,
    UserPermissionsServiceV2,
    UserServiceV2
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
