import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent implements OnInit {
  @Output() button: EventEmitter<any> = new EventEmitter();
  @Output() buttonStateChange = new EventEmitter<boolean>();
  @Input() title = 'Page Title';
  @Input() icon = '';
  @Input() color = '';
  @Input() btnText = '';
  @Input() condition = true;
  @Input() btnCondition: boolean;

  constructor() {
  }

  ngOnInit() {
    if (this.color === '') {
      this.color = 'primary';
    }
  }

  changeBooleanValue() {
    this.btnCondition = !this.btnCondition;
    this.buttonStateChange.emit(this.btnCondition);
  }

}
