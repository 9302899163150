import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PostPutDelApiReply} from '../core/models/post-put-delete-api-reply.model';
import {GetApiReply} from '../core/models/get-api-reply.model';
import {ConfigService} from '../core/services/config.service';

@Injectable()
export class AdminService {
  statusIds = '';
  location = null;
  storageArea = [];
  deliveryList = null;

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  /** Old API */

  getHttpOptionsForExports() {
    return {
      headers: new HttpHeaders({
        'content-type': 'application/text',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
      }),
      responseType: 'text' as 'json',
    };
  }

  getItems(url) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}${url}`,
      this.conf.getHttpOptions()
    );
  }

  createItem(url, item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}${url}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  creatItem(url, item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}${url}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  updateItem(url, item) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}${url}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  deleteItems(url, ids) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}${url}?id=[${ids}]`,
      this.conf.getHttpOptions()
    );
  }

  getEdiPreferences() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}edi_preferences`,
      this.conf.getHttpOptions(),
    );
  }

  createEdiPreferences(ediPreferences) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}edi_preferences`,
      ediPreferences,
      this.conf.getHttpOptions(),
    );
  }

  updateEdiPreferences(ediPreferences) {
    return this.http.put<PostPutDelApiReply>(
      // `${this.conf.getOldServerAddress()}edi_preferences/${ediPreferences.name}`,
      `${this.conf.getServerAddress()}edi_preferences/${ediPreferences.id}`,
      ediPreferences,
      this.conf.getHttpOptions(),
    );
  }

// End of EDI Preferences Section
// __________________________________________________________________
// User Administration section uses user_details table.

  getHandhelds(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}handhelds?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createHandheld(handheld) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}handhelds`,
      handheld,
      this.conf.getHttpOptions(),
    );
  }

  updateHandheld(handheld) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}handhelds?id=eq.${handheld.id}`,
      handheld,
      this.conf.getHttpOptions()
    );
  }

  deleteHandheld(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}handhelds?id=eq.${id}`,
      this.conf.getHttpOptions(),
    );
  }

  getUsers(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}users?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

// End of Handheld Section
// __________________________________________________________________

// Route links section

  getHandheldToRoute(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}route_links?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createHandheldToRoute(hhToRoute) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}route_links`,
      hhToRoute,
      this.conf.getHttpOptions(),
    );
  }

  deleteHandheldToRouteLink(id) {
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}route_links?id=eq.${id}`,
      this.conf.getHttpOptions()
    );
  }

  exportSurfCustomers(ids): Observable<string> {
    let filter = '';
    if (ids !== '') {
      filter = `?customer_ids=[${ids}]`;
    }
    const httpOption = this.getHttpOptionsForExports();
    return this.http.get<string>(
      `${this.conf.getServerAddress()}exports/surf_accounts/customers${filter}`,
      httpOption,
    );
  }

  exportBrcCustomers(ids): Observable<PostPutDelApiReply> {
    let filter = '';
    if (ids !== '') {
      filter = `?customer_ids=[${ids}]`;
    }
    const httpOption = this.getHttpOptionsForExports();
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}exports/big_red_cloud/customers${filter}`,
      {},
      httpOption,
    );
  }

  exportBrcProducts(ids): Observable<PostPutDelApiReply> {
    let filter = '';
    if (ids !== '') {
      filter = `?product_ids=[${ids}]`;
    }
    const httpOption = this.getHttpOptionsForExports();
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}exports/big_red_cloud/products${filter}`,
      {},
      httpOption
    );
  }

  exportSageCustomer(ids): Observable<string> {
    let filter = '';
    if (ids !== '') {
      filter = `?customer_ids=[${ids}]`;
    }
    const httpOption = this.getHttpOptionsForExports();
    return this.http.get<string>(
      `${this.conf.getServerAddress()}exports/sage/customers?customers${filter}`,
      httpOption
    );
  }

  exportSageProduct(ids): Observable<string> {
    let filter = '';
    if (ids !== '') {
      filter = `?product_ids=[${ids}]`;
    }
    const httpOption = this.getHttpOptionsForExports();
    return this.http.get<string>(
      `${this.conf.getServerAddress()}exports/sage/products${filter}`,
      httpOption
    );
  }

  exportSurfProducts(ids): Observable<string> {
    let filter = '';
    if (ids !== '') {
      filter = `?product_ids=[${ids}]`;
    }
    const httpOption = this.getHttpOptionsForExports();
    return this.http.get<string>(
      `${this.conf.getServerAddress()}exports/surf_accounts/products${filter}`,
      httpOption,
    );
  }

  getLocation() {
    return {...this.location};
  }

  getStorageArea() {
    return this.storageArea.slice(0);
  }

  getPreference(optionalParams: string) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}preferences?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createPreference(preference) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}preferences`,
      preference,
      this.conf.getHttpOptions()
    );
  }

  updatePreference(preference, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}preferences?id=eq.${id}`,
      preference,
      this.conf.getHttpOptions()
    );
  }

  createNominalCode(nominalCode) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}accounting_nominal_codes`,
      nominalCode,
      this.conf.getHttpOptions()
    );
  }
}
