import { Component, OnInit, Input } from '@angular/core';
import * as JsBarcode from 'jsbarcode';


@Component({
  selector: 'app-barcode-viewer',
  templateUrl: './barcode-viewer.component.html',
  styleUrls: ['./barcode-viewer.component.css']
})
export class BarcodeViewerComponent implements OnInit {
  @Input() set _barcodeValue(barcodeValue: string) {
    this.barcodeValue = barcodeValue;
    }
  @Input() set _name(name: string) {
    this.name = name;
  }
  barcodeValue;
  name;
  constructor() {
  }

  ngOnInit() {
  }


}
