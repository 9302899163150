import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputCreateFormItem} from '../../models/input-create-from-item.model';
import {DropdownCreateFormItem} from '../../models/dropdown-create-form-item.model';
import {MultiSelectDropdownCreateFormItem} from '../../models/multi-select-dropdown-create-form-item.model';
import {ActivationCreateFormItem} from '../../models/activation-create-form-item.model';
import {DateCreateFormItem} from '../../models/date-create-form-item.model';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ErrorMessageService} from '../error-messages/error-message.service';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.css']
})
export class EditFormComponent implements OnInit {
  @Input() title: string;
  @Input() id: number;
  @Input() inputFormItems: InputCreateFormItem[];
  @Input() inputFormDropdownItems: DropdownCreateFormItem[];
  @Input() inputFormMultiSelectDropdownItems: MultiSelectDropdownCreateFormItem[];
  @Input() inputFormActivationButtons: ActivationCreateFormItem[];
  @Input() inputDateFormItems: DateCreateFormItem[];
  @Output() createdObject = new EventEmitter<any>();
  errors = [];
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});
  showEdit = true;

  constructor(private errorS: ErrorMessageService) {
  }

  ngOnInit() {
  }

  validSubmit() {
    let itIsValid = true;
    let indexOfForms = 0;
    const inputIndexErrors = [];
    for (const it of this.inputFormItems.filter(ifi => ifi.required)) {
      if (it.userInput === null || it.userInput === '') {
        inputIndexErrors.push(indexOfForms);
        itIsValid = false;
      }
      indexOfForms++;
    }
    let dropErrorIndex = 0;
    const dropDownErrors = [];
    for (const it of this.inputFormDropdownItems.filter(ifi => ifi.required)) {
      if (it.itIsAForeignKey && it.id === null) {
        dropDownErrors.push(dropErrorIndex);
        itIsValid = false;
      }
      dropErrorIndex++;
    }
    if (!itIsValid) {
      for (const i of inputIndexErrors) {
        this.errorS.newErrorMessage(`${this.inputFormItems.filter(ifi => ifi.required)[i].heading} is not valid`);
      }
      for (const i of dropDownErrors) {
        this.errorS.newErrorMessage(`${this.inputFormDropdownItems.filter(ifi => ifi.required)[i].heading} is not valid`);
      }
    }
    return itIsValid;
  }

  setUserChoice(item, i) {
    this.inputFormDropdownItems[i].userChoice = {...item};
  }

  applyDates(theDate, i) {
    this.inputDateFormItems[i].dateValue = new Date(new Date(theDate).toISOString().split('T')[0]).toISOString();
  }

  submit() {
    // for (const it of this.inputFormDropdownItems) {
    //   const listIndex = it.list.findIndex( x => x[it.listAccess] === it.userInput);
    //   if (listIndex !== -1) {
    //     it.userChoice = it.list[listIndex];
    //   }
    // }
    const newItem = {id: this.id};
    for (const it of this.inputFormItems) {
      if (it.numberValue) {
        if (!it.userInput) {
          newItem[it.valuesToAccess] = 0;
        } else {
          newItem[it.valuesToAccess] = it.userInput;
        }
      } else {
        newItem[it.valuesToAccess] = it.userInput;
      }
    }
    for (const it of this.inputFormDropdownItems) {
      if (it.itIsAForeignKey) {
        newItem[it.valueToAccess + '_id'] = it.userChoice.id;
      } else {
        newItem[it.valueToAccess] = it.userChoice;
      }
    }
    for (const it of this.inputFormActivationButtons) {
      newItem[it.valuesToAccess] = it.buttonActive;
    }
    for (const it of this.inputDateFormItems) {
      newItem[it.valuesToAccess] = it.dateValue;
    }
    if (this.validSubmit() === true) {
      this.createdObject.emit(newItem);
    }
  }

  submit2() {
    this.showEdit = false;
  }
}
