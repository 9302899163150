import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {NGXLogger} from 'ngx-logger';
import {AuthService} from '../core/auth/auth.service';
import {FinanceService} from '../core/services/finance.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('dashboard', {static: false, read: ElementRef}) dashboard: ElementRef;
  @ViewChild('pieChart', {static: false, read: ElementRef}) myPieChart: ElementRef;
  @ViewChild('barChart', {static: false, read: ElementRef}) myBarChart: ElementRef;
  @ViewChild('lineChart', {static: false, read: ElementRef}) myLineChart: ElementRef;
  loading = false;

  /*** Bar Chart ***/
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartPlugins = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 5 / 4,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true

      }]
    },
    title: {
      display: true,
      text: 'Product Category Sales',
      fontSize: 18,
      fontColor: '#123d54'
    },
    tooltips: {}
  };

  /*** Line Chart ***/
  lineChartType: ChartType = 'line';
  lineChartLegend = false;
  lineChartLegendOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true

      }]
    },
    title: {
      display: true,
      text: 'Customer Sales',
      fontSize: 18,
      fontColor: '#123d54'
    },
    legend: {
      display: true,
      position: 'left',
      labels: {
        // fontFamily: "Comic Sans MS",
        // boxWidth: 10
      },
    },
    tooltips: {}
  };
  lineChartOptions: ChartOptions = {
    responsive: true,
    // aspectRatio: 4 / 5,
    aspectRatio: 5 / 4,
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          display: true // Vertical gridlines
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          display: false // Horizontal gridlines
        }
      }]
    },
    elements: {
      line: {
        fill: false // Choose if the lines are filled from the bottom
      }
    },
    title: {
      display: true,
      text: 'Customer Sales',
      fontSize: 18,
      fontColor: '#123d54'
    },
    legend: {
      display: true,
      // position: 'bottom',
      // position: 'left',
      labels: {
        // fontFamily: "Comic Sans MS",
        // boxWidth: 10
      },
    },
    // legendCallback(chart) {},
    tooltips: {}
  };
  // legendData: Array<ChartLegendItem> = [];

  /*** Doughnut Chart ***/
  doughnutChartType: ChartType = 'doughnut';
  doughnutLegend = true;

  doughnutChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 5 / 4,
    scales: {},
    title: {
      display: true,
      text: 'Route Sales',
      fontSize: 18,
      fontColor: '#123d54'
    },
  };

  WEEKS_TO_GET = 6;
  productCategoryChartLabels = [];
  productCategoryChartData: ChartDataSets[] = [
    {data: [0, 0, 0, 0, 0, 0], label: 'No Data', stack: 'a'},
  ];
  customerChartLabels = [];
  customerChartData: ChartDataSets[] = [
    {data: [0, 0, 0, 0, 0, 0], label: 'No Data'},
  ];
  routeChartLabels = ['No Data'];
  routeChartData: ChartDataSets[] = [
    {data: [0], label: 'No Data'},
  ];
  groupedData;
  productCategory;

  productCategoryAverage = 0;
  customerAverage = 0;
  routeAverage = 0;

  constructor(
    public auth: AuthService,
    private financeS: FinanceService,
    private logger: NGXLogger
  ) {
  }

  async ngOnInit() {
    if (this.auth.isAuthenticated()) {
      const dates = this.getDates();
      this.groupedData = await this.getDashboardData(dates);
      this.createCustomerGraph(this.groupedData.customerNameGroupedTransactions, dates.weekIntervals);
      this.createProductCatGraph(this.groupedData.productCategoryNameGroupedTransactions, dates.weekIntervals);
      this.createRouteGraph(this.groupedData.routeNameGroupedTransactions);

      this.productCategoryAverage = this.getGroupAverage(this.groupedData.productCategoryNameGroupedTransactions);
      this.customerAverage = this.getIntervalAverage(this.groupedData.customerNameGroupedTransactions);
      this.routeAverage = this.getGroupAverage(this.groupedData.routeNameGroupedTransactions);
    }
  }

  getDates() {
    const reportTo = moment().isoWeekday(7).endOf('day');
    const reportFrom = reportTo.clone().subtract(this.WEEKS_TO_GET - 1, 'weeks').startOf('day');
    const weekIntervals = [];
    for (let i = this.WEEKS_TO_GET - 1; i >= 0; i--) {
      weekIntervals.push(reportTo.clone().subtract(i, 'weeks'));
    }

    return {
      reportFrom,
      reportTo,
      weekIntervals
    };
  }

  async getDashboardData(dates) {
    this.loading = true;
    const dashboard_columns = ['transaction_date', 'customer_name', 'product_name', 'product_category_name', 'route_name', 'gross_value'];
    const payload = {
      'parameters': {
        'report_from': dates.reportFrom.toISOString(),
        'report_to': dates.reportTo.toISOString(),
        'best_before_date_from': '',
        'best_before_date_to': '',
        'groupings': `{product_category}`,
        'columns': `{${dashboard_columns}}`,
        'filters': {
          'states': '{100, 200, 400}',
        }
      }
    };

    this.logger.info('Payload', payload.parameters);
    const response = await this.financeS.getDashboardData(payload).toPromise();
    this.logger.log('response', response);
    // @ts-ignore
    if (response.ok && response.body[0].data.length === 0) {
      this.logger.warn('No Data', response.body[0].data);
      this.loading = false;
      // @ts-ignore
    } else if (response.ok && response.body[0].data.length > 0) {
      this.logger.log('Data found', response.body[0].data);
      const customerNameGroupedTransactions = this.groupTransactions(response.body[0].data, 'customer_name', dates.weekIntervals);
      const productCategoryNameGroupedTransactions =
        this.groupTransactions(response.body[0].data, 'product_category_name', dates.weekIntervals);
      const routeNameGroupedTransactions = this.groupTransactions(response.body[0].data, 'route_name', dates.weekIntervals);
      this.loading = false;
      return {
        customerNameGroupedTransactions,
        productCategoryNameGroupedTransactions,
        routeNameGroupedTransactions
      };
    } else {
      this.logger.error(JSON.stringify(response, null, 2));
      throw new Error('Error Getting data');
    }
  }

  groupTransactions(transactions, sortKey, intervals) {
    const groupedTransactions = {};
    transactions.forEach(transaction => {
      if (!Object.keys(groupedTransactions).includes(transaction[sortKey])) {
        groupedTransactions[transaction[sortKey]]
          = {transactionCount: 0, totalValue: 0, intervalTotals: new Array(intervals.length).fill(0)};
      }
      groupedTransactions[transaction[sortKey]] = this.appendToGrouping(groupedTransactions[transaction[sortKey]], transaction, intervals);
    });
    return groupedTransactions;
  }

  appendToGrouping(grouping, newTransaction, intervals) {
    grouping.transactionCount += 1;
    grouping.totalValue += newTransaction.gross_value;
    for (let i = 0; i < intervals.length; i++) {
      if (moment(newTransaction.transaction_date) < intervals[i]) {
        grouping.intervalTotals[i] += newTransaction.gross_value;
        break;
      }
    }
    return grouping;
  }

  createCustomerGraph(groupedData, labels) {
    const newCustomerChartData: ChartDataSets[] = [];
    for (const label of Object.keys(groupedData)) {
      newCustomerChartData.push({
        label,
        data: groupedData[label].intervalTotals,
      });
    }
    this.customerChartLabels = labels.map(label => label.format('Do MMM'));
    this.customerChartData = newCustomerChartData;
  }

  createProductCatGraph(groupedData, labels) {
    const newProductCategoryData: ChartDataSets[] = [];
    for (const label of Object.keys(groupedData)) {
      newProductCategoryData.push({
        label,
        data: groupedData[label].intervalTotals,
      });
    }
    this.productCategoryChartLabels = labels.map(label => label.format('Do MMM'));
    this.productCategoryChartData = newProductCategoryData;
  }

  createRouteGraph(groupedData) {
    const data = [];
    const labels = [];
    for (const label of Object.keys(groupedData)) {
      data.push(groupedData[label].totalValue);
      labels.push(label);
    }
    this.routeChartLabels = labels;
    this.routeChartData = [{data}];
  }

  getItemTotal(item) {
    // this.logger.debug('Item total:', item.data);
    let total = 0;
    if (item.data.length !== 0) {
      item.data.forEach(a => {
        total += a;
      });
    }
    return total;
  }

  getGroupAverage(groupedData) {
    const groupCount = Object.keys(groupedData).length;
    let overallTotal = 0;
    for (const group of Object.keys(groupedData)) {
      overallTotal += groupedData[group].totalValue;
    }

    return overallTotal / groupCount;
  }

  getIntervalAverage(groupedData) {
    const intervalCount = groupedData[Object.keys(groupedData)[0]].intervalTotals.length;
    let overallTotal = 0;
    for (const group of Object.keys(groupedData)) {
      overallTotal += groupedData[group].totalValue;
    }
    return overallTotal / intervalCount;
  }
}
