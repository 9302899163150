import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import {NGXLogger} from 'ngx-logger';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.css']
})
/*
@author christopher buggy
to use this component bind newDate to a variable in the outer component
it will produce a string value removing the time from it.
*/
export class DateSelectionComponent implements OnInit {
  faCalendar = faCalendar;

  constructor(
    private logger: NGXLogger
  ) {
  }
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});

  /***Using MomentJS **
   // now = moment();
   // startOfDay = this.now.startOf('day');
   //
   // startOfDayAsDate: String = moment(this.startOfDay.format()).toISOString();
   // selectedDate = [this.startOfDayAsDate];

   @Input() placement = 'bottom';
   @Output() newDate = new EventEmitter<String[]>();
   @Input() set _preSetDate(preSetDate) {
      console.log('preSetDate: ', preSetDate);
      // this.selectedDate = new Date(preSetDate);
      const date: String = moment(preSetDate[0]).startOf('day').toISOString();
      // const endDate: String = moment(preSetDate[1]).endOf('day').toISOString();
      this.selectedDate = [date];
    }

   ** ***/

  /***Without Moment  ***/
  selectedDate = new Date(new Date(Date.now()).toISOString().split('T')[0]).toISOString();

  @Input() placement = 'bottom';
  @Input() moment = false;
  @Input() momentOutputFormat = 'YYYY-MM-DD';
  @Output() newDate = new EventEmitter<string>();
  now = moment();

  @Input() _preSetDate(preSetDate) {
    this.selectedDate = new Date(new Date(preSetDate).toISOString().split('T')[0]).toISOString();
  }

  ngOnInit() {
  }

  /***Without Moment  ***/
  setDate(date) {
    if (this.moment) {
      const momentDate = moment(date).startOf('day').format(this.momentOutputFormat);
      const prettyDate = moment(date).startOf('day').format('Do MMM, YYYY');
      this.selectedDate = momentDate;
      // console.log( 'From date-selection setDate: ' + endOfLastDay);
      this.newDate.emit(momentDate);
      this.logger.debug('Date selected:', prettyDate);
    } else {
      const momentDate = moment(date).startOf('day').format(this.momentOutputFormat);
      const prettyDate = moment(date).startOf('day').format('Do MMM, YYYY');
      this.selectedDate = momentDate;
      // console.log( 'From date-selection setDate: ' + endOfLastDay);
      this.newDate.emit(momentDate);
      this.logger.debug('Date selected:', prettyDate);
    }
  }

}
