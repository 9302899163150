import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CommonService} from '../../../common.service';

@Component({
  selector: 'app-view-all-elements',
  templateUrl: './view-all-elements.component.html',
  styleUrls: ['./view-all-elements.component.css']
})
/*
This component gives the ability to see all data stored in table.
It will show what ever is given in list[0] list[1] is what table it will access list[2] is the data included and list[3]
is for what you want to sort by
*/
export class ViewAllElementsComponent implements OnInit {
  title: string;
  closeBtnName: string;
  data = [];
  loading = false;
  list: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private coreS: CommonService
  ) {

  }

  ngOnInit() {
    this.loading = false;
    console.log(this.list);
    this.getData();
  }

  getData() {
    this.loading = true;
    this.coreS.getViewListData(this.list[1], this.list[2], this.list[3]).subscribe(res => {
      if (res.success) {
        const data = [];
        res.data.forEach(element => {
          data.push(element[this.list[0]]);
        });
        this.data = data;
        this.loading = false;
      }
    });
  }

  onHide() {
    this.bsModalRef.hide();
  }


}
