import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
// import { NgxBootstrapMultiselectDropdownModule } from 'ngx-bootstrap-multiselect-dropdown';
import {ViewAllElementsComponent} from './view-all-elements/view-all-elements.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {CommonService} from '../../common.service';

@Component({
  selector: 'app-rest-filtering-input',
  templateUrl: './rest-filtering-input.component.html',
  styleUrls: ['./rest-filtering-input.component.css']
})

// @author Chris buggy
// This component loads a dropdown of options using the api
// Use this for users to search for an item.
// ValueToTarget is the value you want to show in the dropdown and what you are running
// Your search aginst.
// targetTable is the name of the db table you want to filter on
// sortBy is what you want to sort the list by
// valueToTarget is what you searching for in the db.
// columns are the columns you wish to return to you. mostly use id and name
// empytInputsAllowed allows a search on an empyt string it will return first 10 items.
// autoFill will auto select if only one option avialble
// specialConditions is when you need some kind of constraint such as customer_id to fetch items belonging to a customer
// afterload Filter is to filter after a call to deal with limitations in apis
// preSelected is to set the userInput value at loadup.
// emitNull allows null to be emited this will be useful for dealing with optional forign keys
// the user choice is emited via the chosenItem;
export class RestFilteringInputComponent implements OnInit {
  filterList;
  lastFilter = 0;
  userSelection = {valueToAccess: '', id: null};
  userInput = '';
  showList = false;
  loading = false;
  checkForIsActive = false;
  filterCount = 0;
  @Input() valueToAccess: string;
  @Input() targetTable: string;
  @Input() sortBy: string;
  @Input() valueToTarget: string;
  @Input() columns = '';
  @Input() emptyInputsAllowed = true;
  @Input() autoFill = true;

  @Input() set specialConditions(specialCondition: string) {
    this.specialCondition = specialCondition;
    this.filter();
  }

  @Input() set _checkForActiveState(isActive: boolean) {
    this.checkForIsActive = isActive;
  }

  @Input() afterLoadFilter = null;

  @Input()
  set preSelected(value: string) {
    if (value !== this.userInput) {
      this.lastFilter = 0;
      this.userInput = value;
      this.filter();
    }
  }

  filterRequest;
  @Input() emitNull = false;
  @Output() chosenItem = new EventEmitter<any>();
  specialCondition = '';
  optionsList = [];
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private coreS: CommonService
  ) {
  }
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  ngOnInit() {
    if (this.emptyInputsAllowed) {
      this.filter();
    }
  }

  userSelected(theChosen) {
    this.userInput = String(theChosen[this.valueToTarget]);
    this.userSelection = {...theChosen};
    this.chosenItem.emit({...theChosen});
    this.showList = false;
  }

  openViewAllComponent() {
    const initialState = {
      list: [
        this.valueToAccess,
        this.targetTable,
        this.columns,
        this.sortBy
      ],
      title: 'Modal with component'
    };
    this.bsModalRef = this.modalService.show(ViewAllElementsComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  filter() {
    if ((this.userInput.length > 0 || this.emptyInputsAllowed) && Date.now() > this.lastFilter + 500) {
      this.filterCount = this.filterCount > 10000 ? 1 : this.filterCount + 1;
      const localCount = this.filterCount;
      this.loading = true;
      this.coreS.getDataFromDbBySearch(
        this.targetTable,
        this.valueToTarget,
        this.sortBy,
        this.columns,
        this.userInput,
        this.checkForIsActive,
        this.specialCondition
      ).subscribe(res => {
        if (localCount === this.filterCount) {
          if (res.data) {
            if (this.afterLoadFilter === null) {
              this.filterList = res.data;
              this.loading = false;
              if (res.data.length === 1 && this.autoFill) {
                this.userSelected(res.data[0]);
              }
            } else {
              if (this.afterLoadFilter.conditionState === 'not') {
                this.filterList = res.data.filter(rd => {
                  return rd[this.afterLoadFilter.item] !== this.afterLoadFilter.condition;
                });
                this.loading = false;
                if (res.data.length === 1 && this.autoFill) {
                  this.userSelected(res.data[0]);
                }
              } else {
                this.filterList = res.data.filter(rd => {
                    return rd[this.afterLoadFilter.item] === this.afterLoadFilter.condition;
                  }
                );
                this.loading = false;
                if (res.data.length === 1 && this.autoFill) {
                  this.userSelected(res.data[0]);
                }
              }
            }
          }
        }
      });
      this.lastFilter = Date.now();
    } else if (this.userInput === '') {
      this.chosenItem.emit(null);
    }
  }


  mouseEnter() {
    this.showList = true;
  }

  mouseLeave() {
    this.showList = false;
  }

  backSpace() {
    this.userInput = '';
    this.filter();
  }

}
