import {Injectable} from '@angular/core';
import {GetApiReply} from '../models/get-api-reply.model';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BackendConfigurationService} from './backend-configuration.service';
import {SalestrackApiConfService} from './salestrackApi-conf.service';

@Injectable({
  providedIn: 'root'
})
export class ValueTableLookupService {

  constructor(
    private http: HttpClient,
    private configService: BackendConfigurationService
  ) {
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Replace with logging service later.
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }

  getBatchStatus(): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(`${this.configService.getServerAddress()}batch_statuses`)
      .pipe(
        tap(status => {
          catchError(this.handleError('batch_statuses', []));
          console.log(status);
        })
      );
  }

  getQuantifiers(): Observable<GetApiReply> {
    return this.http.get<GetApiReply>(`${this.configService.getServerAddress()}quantifiers`)
      .pipe(
        tap(quantifier => {
          catchError(this.handleError('quantifiers', []));
          console.log(quantifier);
        })
      );
  }

  getCustomers(): Observable<GetApiReply> {
    const httpHeaders = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.configService.getServerAddress()}customers`,
      httpHeaders
    ).pipe(
      tap(customers => {
        catchError(this.handleError('customers', []));
        console.log(customers);
        return customers;
      })
    );
  }

  getProducts(): Observable<GetApiReply> {
    const httpHeaders = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.configService.getServerAddress()}products`,
      httpHeaders
    ).pipe(
      tap(products => {
        catchError(this.handleError('products', []));
        return products;
      })
    );
  }

  getEdiPreferences(): Observable<GetApiReply> {
    const httpHeaders = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.configService.getServerAddress()}edi_profiles`,
      httpHeaders
    ).pipe(
      tap(ediPreferences => {
        catchError(this.handleError('edi_profiles', []));
        return ediPreferences;
      })
    );
  }
}
