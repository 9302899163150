import {Injectable} from '@angular/core';
import {ConfigService} from '../config.service';

@Injectable({
    providedIn: 'root'
})
export class SalestrackApiConfService {
  postgrestApiService = new ConfigService();

    constructor() {
    }

    getServerAddress() {
      return this.postgrestApiService.getServerAddress();
    }
}
