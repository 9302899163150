import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import {DateAdapter} from '@angular/material';

@Component({
  selector: 'app-mat-date-range-selection',
  templateUrl: './mat-date-range-selection.component.html',
  styleUrls: ['./mat-date-range-selection.component.css']
})
export class MatDateRangeSelectionComponent implements OnInit {
  @Output() newPlacement = new EventEmitter<string>();
  bsConfig: Partial<BsDaterangepickerConfig> = Object.assign({}, {
    containerClass: 'theme-dark-blue', placement: this.newPlacement, displayOneMonthRange: true, displayMonths: 1});

  now = moment();
  startOfDay = this.now.startOf('day');
  endOfDay = this.now.endOf('day');

  startOfDayAsDate: string = moment(this.startOfDay.format()).toISOString();
  endOfDayAsDate: string = moment(this.endOfDay.format()).toISOString();

  selectedDate = [
    this.startOfDayAsDate,
    this.endOfDayAsDate
  ];
  // @Input() placement = 'bottom';
  @Output() newDate = new EventEmitter<string[]>();
  @Input() set _preSetDate(preSetDate) {
    if (typeof preSetDate === 'string') {
      preSetDate = preSetDate.split(',');
    }
    console.log(preSetDate);
    const startDate: string = moment(preSetDate[0]).startOf('day').toISOString();
    const endDate: string = moment(preSetDate[1]).endOf('day').toISOString();
    this.selectedDate = [startDate, endDate];
    console.log(this.selectedDate);
  }

  constructor() {
  }

  ngOnInit() {
    this.setPlacement(this.newPlacement);
  }

  setDate(date) {
    // console.log( 'From date-range-selection setDate: ' + date[0].toISOString());
    // console.log( 'From date-range-selection setDate: ' + date[1].toISOString());

    const startOfFirstDay: string = moment(date[0]).startOf('day').toISOString();
    const endOfLastDay: string = moment(date[1]).endOf('day').toISOString();

    console.log('From date-range-selection setDate: ' + startOfFirstDay);
    console.log('From date-range-selection setDate: ' + endOfLastDay);

    this.selectedDate = [startOfFirstDay, endOfLastDay];
    this.newDate.emit(
      [
        startOfFirstDay,
        endOfLastDay
      ]
    );
  }

  setPlacement(placement) {
    this.newPlacement.emit(
      placement
    );
    console.log('placement');
    console.log(placement);
  }

}
