import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-delete-list',
  templateUrl: './delete-list.component.html',
  styleUrls: ['./delete-list.component.css']
})
export class DeleteListComponent implements OnInit {
  @Input() heading: string;
  @Input() valuesToAccess: string;
  @Input() list: any[];
  @Output() listToDelete = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();
  modalRef: BsModalRef;
  message: string;

  constructor(
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
  }

  confirmDelete() {
    const idList = [];
    for (const item of this.list) {
      idList.push(item.id);
    }
    this.listToDelete.emit(idList);
  }

  removeFromList(i) {
    this.list.splice(i, 1);
    if (this.list.length < 1) {
      this.goBack.emit(true);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  confirm(): void {
    this.confirmDelete();
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }


}
