import {Injectable} from '@angular/core';
import {GetApiReply} from './core/models/get-api-reply.model';
import {pluck, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient) {
  }

  getQuotes() {
    // return this.http.get(`./assets/sample-data/developer-quotes.json`)
    return this.http.get(`https://gist.githubusercontent.com/b1nary/ea8fff806095bcedacce/raw/6e6de20d7514b93dd69b149289264997b49459dd/enterpreneur-quotes.json`)
      .pipe(tap(res => {
        return {
          data: res,
        };
      })
    );
  }
}
