/*
This component will display a list of barcodes that the user can print using the PrintJob library from npm
Takes in
author: Christopher buggy.
*/

import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import PrintJob from 'print-job';
import * as JsBarcode from 'jsbarcode';
import {BarcodeList} from '../../models/barcode-list.model';

@Component({
  selector: 'app-barcode-list',
  templateUrl: './barcode-list.component.html',
  styleUrls: ['./barcode-list.component.css']
})
export class BarcodeListComponent implements OnInit {
  // the barcode list will be an array of objects with a name and a barcode e.g {name: x, barcode: y}
  @ViewChild('barcodeToPrints', {static: false, read: ElementRef}) barcodeToPrints: ElementRef;
  @Input() set _barcodeList (barcodeList: BarcodeList[]) {
    this.barcodeList = [...barcodeList];
    for (const i of barcodeList) {
      console.log(i.id);
      JsBarcode('.barcode1').init();
    }
  }
  @Input() set _newBarcode(barcode: BarcodeList) {
    console.log(barcode);
    if (barcode) {
      const barcodes = this.barcodeList.slice(0);
    barcodes.push(barcode);
    this.barcodeList = barcodes;
    for (const i of barcodes) {
      JsBarcode('.barcode1').init();
    }
    }
  }
  barcodeList: BarcodeList[] = [];
  printing = false;
  @Output() removeIndex = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  printList() {
    this.printing = true;
    setTimeout(() => {
      PrintJob.print(this.barcodeToPrints.nativeElement);
    }, 1000 );
    setTimeout(() => {this.printing = false; }, 1000);
  }

  genBarcodes(barcodeList) {
    for (const i of barcodeList) {
      JsBarcode(`#${i.id}`, i.barcode, {
      format: 'CODE128'
    });
    }
  }

  remove(i) {
    this.barcodeList.splice(i, 1);
    this.removeIndex.emit(i);
  }

}
