import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    console.log(' auth worked');
    this.router.navigate(['/dashboard'], {relativeTo: this.route});
    // this.router.navigate(['/transactions/dockets'], {relativeTo: this.route});
  }

}
