import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {GetApiReply} from '../models/get-api-reply.model';
import {PostPutDelApiReply} from '../models/post-put-delete-api-reply.model';

@Injectable({
  providedIn: 'root'
})
export class LogisticsService {
  deliveryList = null;

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  /** Deliveries */

  getDeliveryLists(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}incoming_delivery_lists?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDeliveryList(deliveryList) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}incoming_delivery_lists`,
      deliveryList,
      this.conf.getInsertHeaders()
    );
  }

  updateDeliveryList(deliveryList, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}incoming_delivery_lists?id=eq.${id}`,
      deliveryList,
      this.conf.getHttpOptions()
    );
  }

  setDeliveryList(deliveryList) {
    this.deliveryList = {...deliveryList};
  }

  getDeliveryListItems(optionalParams = '', pageIndex: number) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}incoming_delivery_list_items?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDeliveryListItem(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}incoming_delivery_list_items`,
      item,
      this.conf.getInsertHeaders()
    );
  }

  createDeliveryListItemProduct(item) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}incoming_delivery_list_item_products`,
      item,
      this.conf.getHttpOptions()
    );
  }

  /** Dispatch */

  // Dispatch Groups
  getDispatchGroups(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_groups?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDispatchGroup(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_groups`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateDispatchGroup(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_groups?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  deleteDispatchGroup(id) {
    id = id.filter(function(item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_groups?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  // Dispatch Group Products

  getDispatchGroupProducts(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_products?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDispatchGroupProduct(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_products`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateDispatchGroupProduct(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_products?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  deleteDispatchGroupProduct(id) {
    id = id.filter(function (item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_products?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  deleteByDispatchGroup(dispatch_list_id) {
    dispatch_list_id = dispatch_list_id.filter(function(item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_items?dispatch_list_id=in.(${dispatch_list_id})`,
      this.conf.getHttpOptions()
    );
  }

  // Dispatch Group schedules
  getDispatchGroupSchedules(optionalParams = '', pageIndex = null) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_schedules?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDispatchGroupSchedule(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_schedules`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateDispatchGroupSchedule(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_schedules?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  deleteDispatchGroupSchedule(id) {
    id = id.filter(function (item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_group_schedules?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  // Dispatch Lists
  getDispatchLists(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_lists?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDispatchList(list) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_lists`,
      list,
      this.conf.getInsertHeaders()
    );
  }

  updateDispatchList(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_lists?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  deleteDispatchList(id) {
    id = id.filter(function(item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_lists?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  // Dispatch List Items
  getDispatchListItems(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_items?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDispatchListItem(dispatchListItem) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_items`,
      dispatchListItem,
      this.conf.getInsertHeaders()
    );
  }

  updateDispatchListItem(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_items?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  deleteDispatchListItem(id) {
    id = id.filter(function (item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_items?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  // Dispatch List Item products
  getDispatchListItemsProducts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_item_products?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createDispatchListItemsProducts(dispatchListItemProduct) {
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}dispatch_list_item_products`,
      dispatchListItemProduct,
      this.conf.getHttpOptions()
    );
  }

  /** Routes */

  getRoutes(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}routes?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  /** Suppliers */

  getSuppliers(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}suppliers?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createSupplier(supplier) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}suppliers`,
      supplier,
      this.conf.getHttpOptions()
    );
  }

  updateSupplier(supplier, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}suppliers?id=eq.${id}`,
      supplier,
      this.conf.getHttpOptions()
    );
  }
}
