import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class BackendConfigurationService {
  configService = new ConfigService();

  constructor() {
  }

  getServerAddress() {
    return this.configService.getOldServerAddress();
  }

  getNewServerAddress() {
    return this.configService.getServerAddress();
  }
}
