import {Component, OnInit, Renderer2} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {OrganisationService} from '../../services/organisation.service';
import {AuthService} from '../../auth/auth.service';
import {UserPermissionController} from '../../controllers/userPermissionsController';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  navItems = [];

  /*** Logo ***/
  logo = null;
  preferencesId = null;
  loaded = false;

  /*** User Data ***/

  id;
  name;
  email;
  username;
  tokenData = {
    email: '',
    username: ''
  };
  userData = null;
  userPerms = null;


  perms;

  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private orgS: OrganisationService,
    private sanitizer: DomSanitizer,
    private ren: Renderer2,
    private userPermissionsController: UserPermissionController
  ) {
  }

  ngOnInit() {
    this.setDefaultPerms();
    this.loadNavData();
  }

  loadNavData() {
    if (this.auth.isAuthenticated()) {
      this.getLogo();
      // this.setNavItems();
      this.getUserData();
      this.getData();
    } else {
      setTimeout(() => this.loadNavData(), 1000);
    }
  }

  setDefaultPerms() {
    this.perms = {
      salesItems: {
        sales: false,
        dockets: false,
      },
      orders: false,
      stockItems: {
        stock: false,
        stock_search: false
      },
      productionItems: {
        production: false,
        production_list: false,
        production_groups: false,
        batch: false
      },
      production: {
        production: false,
        production_list: false,
        production_groups: false,
        batch_control: false
      },
      logisticsItems: {
        logistics: false,
        suppliers: false,
        delivery: false,
        dispatch: false,
      },
      financeItems: {
        finance: false,
        documents: false,
        discounts: false,
        exports: false,
        payments: false,
      },
      customers: true,
      services: false,
      products: true,
      adminItems: {
        admin: false,
        organisation_settings: false,
        financial_settings: false,
        customer_settings: false,
        product_settings: false,
        storage_settings: false,
        handheld_settings: false,
        other_settings: false
      },
      reportItems: {
        admin: false,
        organisation_settings: false,
        financial_settings: false,
        customer_settings: false,
        product_settings: false,
        storage_settings: false,
        handheld_settings: false,
        other_settings: false
      },
      dashboard: false
    };
  }

  getLogo() {
    this.orgS.getLogo().subscribe(
      data => {
        this.logger.info('Loading logo data:', data);
        // data = '';
        if (data !== '') {
          this.logo = 'data:image/png;base64,' + data;
          this.logo = this.sanitizer.bypassSecurityTrustUrl(this.logo);
          this.logger.info('Logo: ' + this.logo);
        }
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.log('Process complete, closing request!');
      }
    );
  }

  getData() {
    this.orgS.getMyOrgId().subscribe(
      res => {
        const id = res.body;
        this.logger.info('Loading organisation ID:', id);
        let optionalParams = 'select=*';
        optionalParams += '&id=eq.' + id;
        this.orgS.getOrganisations(optionalParams).subscribe(
          data => {
            this.logger.info('Loading organisation data for', data.body[0].name);
            const item = data.body[0];
            this.id = item.id;
            this.name = item.name;
          },
          err => {
            this.logger.error('Received error:', err);
          },
          () => {
            this.logger.info('Process complete, closing request!');
          }
        );
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      });
  }

  getUserData() {
    this.auth.getUserInfo().subscribe(
      (data: any) => {
        // Get users email
        this.tokenData.email = data.email;
        this.email = data.email;
        // Get username
        this.tokenData.username = data['https://api.hhsalestrack.com/user_metadata'].username;
        this.getUserPermsNew().then(r => {
        });
        this.getUserPerms();
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  getUserPerms() {
    // Get the email id from the users table.
    // Get the user_roles data by joining the table by the user id and the user_roles user_id (This is a FK in the db)
    // In the joined user_roles table do another join to get the roles data (Another FK in the db)
    let optionalParams = 'select=email,id,user_roles(id,roles(name))';
    optionalParams += '&name=eq.' + this.tokenData.username;
    // Get user data
    this.auth.getUsers(optionalParams).subscribe(
      data => {
        this.userData = data;
        // this.setPermissions();
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  async getUserPermsNew() {
    const userPermissions = await this.userPermissionsController.getUsers(null, null, [this.tokenData.username]);
    this.userPerms = userPermissions[0].user_permissions;
    this.logger.debug('User perms:', this.userPerms);
    // this.setDefaults();
    this.setLogic();
    this.setNavItems();
  }

  setLogic() {
    /** Sales */
    if (this.userPerms.sales.dockets.dockets === true || this.userPerms.sales.dockets.docket_reprint === true) {
      this.perms.salesItems.dockets = true;
    }
    if (this.userPerms.sales.dockets.dockets === true || this.userPerms.sales.dockets.docket_reprint === true) {
      this.perms.salesItems.sales = true;
    }

    /** Orders */
    if (this.userPerms.orders.standing_orders === true || this.userPerms.orders.special_orders === true
      || this.userPerms.orders.service_orders === true) {
      this.perms.orders = true;
    }
    /** logistics */
    // suppliers
    if (this.userPerms.logistics.suppliers.suppliers_list === true) {
      this.perms.logisticsItems.suppliers = true;
    }
    // delivery
    if (this.userPerms.logistics.delivery.delivery_lists === true || this.userPerms.logistics.delivery.create_delivery_list === true) {
      this.perms.logisticsItems.delivery = true;
    }
    // dispatch
    if (this.userPerms.logistics.dispatch.dispatch_groups === true || this.userPerms.logistics.dispatch.create_dispatch_lists === true ||
      this.userPerms.logistics.dispatch.edit_dispatch_lists === true) {
      this.perms.logisticsItems.dispatch = true;
    }
    // Set menu item
    if (this.perms.logisticsItems.suppliers === true || this.perms.logisticsItems.delivery === true
      || this.perms.logisticsItems.dispatch === true) {
      this.perms.logisticsItems.logistics = true;
    }
    /** Stock */
    if (this.userPerms.stock.stock_search.production_location === true || this.userPerms.stock.stock_search.location_search === true ||
      this.userPerms.stock.stock_search.storage_area_search || this.userPerms.stock.stock_search.stock_location === true ||
      this.userPerms.stock.stock_search.stock_level_alert === true) {
      this.perms.stockItems.stock = true;
    }
    if (this.userPerms.stock.stock_search.production_location === true || this.userPerms.stock.stock_search.location_search === true ||
      this.userPerms.stock.stock_search.storage_area_search) {
      this.perms.stockItems.stock_search = true;
    }
    /** Production */
    if (this.userPerms.production.production_list === true || this.userPerms.production.create_production_list === true ||
      this.userPerms.production.production_groups === true || this.userPerms.production.production_inputs === true ||
      this.userPerms.production.production_process === true || this.userPerms.production.batch_control === true) {
      this.perms.production = true;
    }
    /** Finance */
    // documents
    if (this.userPerms.finance.documents.create_invoice === true || this.userPerms.finance.documents.view_invoice === true ||
      this.userPerms.finance.documents.create_statements === true || this.userPerms.finance.documents.create_credit_notes === true) {
      this.perms.financeItems.documents = true;
    }
    // discounts
    if (this.userPerms.finance.discounts.product_discounts === true || this.userPerms.finance.discounts.customer_discounts === true ||
      this.userPerms.finance.discounts.product_quantity_offers === true || this.userPerms.finance.discounts.bulk_discounts === true ||
      this.userPerms.finance.discounts.billing_discounts === true) {
      this.perms.financeItems.discounts = true;
    }
    // exports
    if (this.userPerms.finance.payments.incoming === true || this.userPerms.finance.payments.outgoing === true) {
      this.perms.financeItems.payments = true;
    }
    // Payments
    if (this.userPerms.finance.exports.export_invoices === true || this.userPerms.finance.exports.review_invoices === true ||
      this.userPerms.finance.exports.customer_exports === true || this.userPerms.finance.exports.product_exports === true ||
      this.userPerms.finance.exports.edi_exports === true || this.userPerms.finance.exports.custom_exports === true) {
      this.perms.financeItems.exports = true;
    }
    // Set finance menu item
    if (this.perms.financeItems.documents === true ||
      this.perms.financeItems.discounts === true ||
      this.perms.financeItems.exports === true ||
      this.perms.financeItems.payments === true
    ) {
      this.perms.financeItems.finance = true;
    }
    /** Services */
    if (this.userPerms.services.service_list === true ||
      this.userPerms.services.job_report_builder === true ||
      this.userPerms.services.job_reports === true ||
      this.userPerms.services.create_service_form === true) {
      this.perms.services = true;
    }

    /** Admin Items */
    // organisation_settings
    if (this.userPerms.admin.organisation_settings.company_details === true ||
      this.userPerms.admin.organisation_settings.account_package_setup === true ||
      this.userPerms.admin.organisation_settings.account_package_settings === true ||
      this.userPerms.admin.organisation_settings.billing_details === true ||
      this.userPerms.admin.organisation_settings.logo_upload === true) {
      this.perms.adminItems.organisation_settings = true;
    }
    // financial_settings
    if (this.userPerms.admin.financial_settings.bank_details === true ||
      this.userPerms.admin.financial_settings.account_details === true ||
      this.userPerms.admin.financial_settings.vat_rates === true) {
      this.perms.adminItems.financial_settings = true;
    }
    // customer_settings
    if (this.userPerms.admin.customer_settings.depots === true ||
      this.userPerms.admin.customer_settings.customer_types === true ||
      this.userPerms.admin.customer_settings.routes === true ||
      this.userPerms.admin.customer_settings.billing_frequency === true) {
      this.perms.adminItems.customer_settings = true;
    }
    // product_settings
    if (this.userPerms.admin.product_settings.product_categories === true ||
      this.userPerms.admin.product_settings.sub_product_categories === true ||
      this.userPerms.admin.product_settings.quantifiers === true ||
      this.userPerms.admin.product_settings.create_nominal_codes === true) {
      this.perms.adminItems.product_settings = true;
    }
    // storage_settings
    if (this.userPerms.admin.storage_settings.storage_locations === true ||
      this.userPerms.admin.storage_settings.storage_location_types === true ||
      this.userPerms.admin.storage_settings.storage_areas === true ||
      this.userPerms.admin.storage_settings.storage_area_types === true) {
      this.perms.adminItems.storage_settings = true;
    }
    // handheld_settings
    if (this.userPerms.admin.handheld_settings.create_handhelds === true ||
      this.userPerms.admin.handheld_settings.assign_handhelds_to_routes === true ||
      this.userPerms.admin.handheld_settings.assign_handhelds_to_routes === true) {
      this.perms.adminItems.handheld_settings = true;
    }
    // other_settings
    if (this.userPerms.admin.other_settings.edi_settings === true ||
      this.userPerms.admin.other_settings.commissions === true) {
      this.perms.adminItems.other_settings = true;
    }
    if (this.userPerms.dashboard.sales === true) {
      this.perms.dashboard = true;
    }
    // Set admin menu item
    if (
      this.perms.adminItems.organisation_settings === true ||
      this.perms.adminItems.financial_settings === true ||
      this.perms.adminItems.customer_settings === true ||
      this.perms.adminItems.product_settings === true ||
      this.perms.adminItems.storage_settings === true ||
      this.perms.adminItems.handheld_settings === true ||
      this.perms.adminItems.other_settings === true) {
      this.perms.adminItems.admin = true;
    }
  }

  setNavItems() {
    // Dockets
    const menuDockets = {
      displayName: 'Sales',
      iconName: 'shopping_cart',
      style: 'process',
      perms: this.perms.salesItems.sales,
      children: [
        {
          displayName: 'Dockets',
          iconName: 'group',
          perms: this.perms.salesItems.dockets,
          children: [
            {
              displayName: 'Dockets',
              iconName: 'star_rate',
              perms: this.perms.salesItems.dockets.dockets,
              route: 'what-up-web'
            },
            {
              displayName: 'Dockets Reprint',
              iconName: 'star_rate',
              perms: this.perms.salesItems.dockets.docket_reprint,
              route: 'what-up-web'
            }
          ]
        }
      ]
    };
    this.navItems.push(menuDockets);

    // Orders
    const menuOrders = {
      displayName: 'Sales',
      iconName: 'shopping_cart',
      style: 'process',
      perms: this.perms.orders.sales,
      children: [
        {
          displayName: 'Dockets',
          iconName: 'group',
          perms: this.perms.salesItems.dockets,
          children: [
            {
              displayName: 'Dockets',
              iconName: 'star_rate',
              perms: this.perms.salesItems.dockets.dockets,
              route: 'what-up-web'
            },
            {
              displayName: 'Dockets Reprint',
              iconName: 'star_rate',
              perms: this.perms.salesItems.dockets.docket_reprint,
              route: 'what-up-web'
            }
          ]
        }
      ]
    };

    const menuFinance = {
      displayName: 'Finance',
      iconName: 'account_balance',
      style: 'process',
      perms: this.perms.financeItems.finance,
      children: [
        {
          displayName: 'Documents',
          iconName: 'group',
          perms: this.perms.financeItems.documents,
          children: [
            {
              displayName: 'Invoices',
              iconName: 'person',
              route: 'michael-prentice',
              perms: this.userPerms.finance.documents.create_invoice,
              children: [
                {
                  displayName: 'Create Invoices',
                  iconName: 'star_rate',
                  route: 'material-design',
                  perms: this.userPerms.finance.documents.create_invoice
                },
                {
                  displayName: 'View Invoices',
                  iconName: 'star_rate',
                  route: 'material-design'
                }
              ]
            },
            {
              displayName: 'Statements',
              iconName: 'person',
              route: 'michael-prentice',
              children: [
                {
                  displayName: 'Supplier Statements',
                  iconName: 'star_rate',
                  route: 'material-design'
                }
              ]
            },
            {
              displayName: 'Credit Notes',
              iconName: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          displayName: 'Discounts',
          iconName: 'group',
          children: [
            {
              displayName: 'Product discounts',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Customer discounts',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Product quantity deal',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Bulk discounts',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Billing discounts',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
          ]
        },
        {
          displayName: 'Exports',
          iconName: 'group',
          children: [
            {
              displayName: 'Credit Notes',
              iconName: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          displayName: 'Payments',
          iconName: 'group',
          children: [
            {
              displayName: 'Incoming',
              iconName: 'star_rate',
              route: 'what-up-web'
            },
            {
              displayName: 'Outgoing',
              iconName: 'star_rate',
              route: 'what-up-web'
            }
          ]
        }
      ]
    };
    this.navItems.push(menuFinance);

    // Reports
    const menuReports = {
      displayName: 'Reports',
      iconName: 'analytics',
      style: 'performance',
      children: [
        {
          displayName: 'Sales',
          iconName: 'group',
          children: [
            {
              displayName: 'Create',
              route: 'michael-prentice'
            },
            {
              displayName: 'Saved',
              route: 'michael-prentice'
            },
            {
              displayName: 'Summary',
              route: 'what-up-web'
            }
          ]
        },
      ]
    };
    this.navItems.push(menuReports);

    // Production
    const menuProduction = {
      displayName: 'Production',
      iconName: 'shopping_cart',
      style: 'process',
      perms: this.perms.production,
      children: [
        {
          displayName: 'Create Vouchers',
          iconName: 'group',
          perms: this.perms.production.production_list
        },
        {
          displayName: 'Assigning Voucher Codes',
          iconName: 'group',
          perms: this.perms.production.production_list
        },
        {
          displayName: 'Production Groups',
          iconName: 'group',
          perms: this.perms.production.production_groups
        },
        {
          displayName: 'Production Control',
          iconName: 'group',
          perms: this.perms.production.batch_control
        }
      ]
    };
    this.navItems.push(menuProduction);

    /*

        "user_permissions": [
            {
                "permissions": {
                    "sales": {
                        "dockets": {
                            "dockets": true,
                            "docket_reprint": true
                        }
                    },
                    "orders": {
                        "standing_orders": true,
                        "special_orders": true,
                        "service_orders": false
                    },
                    "production": {
                        "production_list": false,
                        "create_production_list": false,
                        "production_groups": false,
                        "production_inputs": false,
                        "production_process": false,
                        "batch_control": false
                    },
                    "stock": {
                        "stock_search": {
                            "production_location": false,
                            "location_search": false,
                            "storage_area_search": false
                        },
                        "stock_location": false,
                        "stock_level_alert": false
                    },
                    "logistics": {
                        "suppliers": {
                            "suppliers_list": false
                        },
                        "delivery": {
                            "delivery_lists": false,
                            "create_delivery_list": false
                        },
                        "dispatch": {
                            "dispatch_groups": false,
                            "create_dispatch_lists": false,
                            "edit_dispatch_lists": false
                        }
                    },
                    "finance": {
                        "documents": {
                            "create_invoice": true,
                            "view_invoice": true,
                            "create_statements": true,
                            "create_credit_notes": true
                        },
                        "discounts": {
                            "product_discounts": true,
                            "customer_discounts": true,
                            "product_quantity_offers": true,
                            "bulk_discounts": true,
                            "billing_discounts": true
                        },
                        "exports": {
                            "export_invoices": true,
                            "review_invoices": true,
                            "customer_exports": true,
                            "product_exports": true,
                            "edi_exports": true,
                            "custom_exports": true
                        }
                    },
                    "customers": {
                        "customers": true,
                        "customer_group_update": true,
                        "customers_on_routes": true,
                        "customer_specific_prices": true,
                        "customer_specific_price_group_update": true,
                        "inactive_customers": true
                    },
                    "services": {
                        "service_list": false,
                        "create_service_form": false
                    },
                    "products": {
                        "products": true,
                        "multiple_product_update": true,
                        "price_list": true,
                        "multiple_price_update": true,
                        "inactive_products": true
                    },
                    "admin": {
                        "organisation_settings": {
                            "company_details": true,
                            "billing_details": true,
                            "logo_upload": true
                        },
                        "financial_settings": {
                            "bank_details": true,
                            "account_details": true,
                            "vat_rates": true
                        },
                        "customer_settings": {
                            "depots": true,
                            "customer_types": true,
                            "routes": true,
                            "billing_frequency": true
                        },
                        "product_settings": {
                            "product_categories": true,
                            "sub_product_categories": true,
                            "quantifiers": true,
                            "create_nominal_codes": true
                        },
                        "storage_settings": {
                            "storage_locations": false,
                            "storage_location_types": false,
                            "storage_areas": false,
                            "storage_area_types": false
                        },
                        "handheld_settings": {
                            "create_handhelds": true,
                            "assign_handhelds_to_routes": true,
                            "user_controls": true
                        },
                        "other_settings": {
                            "process": true,
                            "edi_settings": true,
                            "commissions": true
                        }
                    },
                    "traceability": {
                        "barcodes": true
                    },
                    "reports": {
                        "sales_reports": {
                            "create": true,
                            "saved": true,
                            "comparison": true,
                            "summary": true
                        },
                        "production_reports": {
                            "create": false,
                            "saved": false,
                            "comparison": false,
                            "summary": false
                        },
                        "servicing_reports": {
                            "create": false
                        },
                        "stock_reports": {
                            "create": false
                        },
                        "financial_reports": {
                            "create": true
                        },
                        "agent_commission_report": {
                            "create": true,
                            "saved": true,
                            "comparison": true,
                            "summary": true
                        }
                    },
                    "dashboard": {
                        "sales": true
                    }
                },
                "id": 15
            }
        ]
     */

  }

}
