import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../../services/alert.service';
import {Subscription} from 'rxjs';
import {ErrorMessageService} from '../error-messages/error-message.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit, OnDestroy {

  dismissible = true;
  alerts = [];

  // Deprec

  @Input() errorMessage: string;
  showError = false;
  subscription: Subscription;

  errorMessages = [];

  constructor(
    private alertS: AlertService
  ) {
    this.subscription = alertS.messageSource$.subscribe(
      alert => {
        console.log('error', alert);
        this.alerts.push(alert);
      }
    );
  }

  ngOnInit() {
  }

  clear() {
    this.alerts = [];
  }

  removeMessage(i) {
    this.errorMessages.splice(i, 1);
    if (this.errorMessages.length === 0) {
      this.showError = false;
    }
  }

  closeErrors() {
    this.errorMessages = [];
    this.showError = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  reset(): void {
    this.alerts = [];
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

}
