import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {GetApiReply} from './models/get-api-reply.model';
import {RestTableFilterModel} from './models/other/rest-table-filter-model';
import {BooleanFilterRestTable} from './models/other/boolean-filter-rest.model';
import {AuthService} from './auth/auth.service';
import {ConfigService} from './services/config.service';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private authS: AuthService,
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  getOrganisationId() {

  }

  getDataFromDbBySearch(
    targetTable: string,
    valueToTarget: string,
    sortBy: string,
    columns: string,
    value: string,
    checkIsActive: boolean,
    specialCondition?: string
  ): Observable<GetApiReply> {
    let include = ``;
    let requestValue = '';
    let isActive = '';
    if (specialCondition.length > 0) {
      specialCondition = `&${specialCondition}`;
    }
    if (value.length > 0) {
      requestValue = `${valueToTarget}=${value}*&`;
    }
    if (checkIsActive) {
      isActive = '&is_active=TRUE';
    }
    if (columns !== '') {
      include = `&$include_columns=[${columns}]`;
    }
    const httpHeaders = this.conf.getHttpOptions();
    const tableParams = `${requestValue}$sort=${valueToTarget}${include}${specialCondition}${isActive}&$page=1&$per_page=10`;
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress() + targetTable}?${tableParams}`,
      httpHeaders
    );
  }

  getTableDataFromDbBySearch(
    targetTable: string,
    sortBy: string,
    columns: RestTableFilterModel[],
    includeColumns: string,
    itemsPerPage: number,
    startDate: string,
    endDate: string,
    targetDateColumn: string,
    specialCondition: string,
    pageIndex: number,
    booleanFilters: BooleanFilterRestTable[],
    nullFilters: BooleanFilterRestTable[]
  ): Observable<GetApiReply> {
    console.log(nullFilters);
    let string = ``;
    let dateString = ``; // For old date filters
    let include = ``;
    let boolString = ``;
    let nullString = ``;
    console.log(specialCondition);
    if (specialCondition !== '') {
      specialCondition = '&' + specialCondition;
    }
    if (includeColumns !== '') {
      include = `&$include_columns=[${includeColumns}]`;
    }
    columns.forEach(c => {
      console.log(c);
      if (c.userInput !== '' && c.isItAForeignKey === false) {
        string += `${c.dbColumn}=*${c.userInput}*&`;
      }
      if (c.userInput !== '' && c.isItAForeignKey === true) {
        string += string += `${c.dbColumn}=${c.userInput}&`;
      }
    });
    if (nullFilters.length > 0) {
      console.log('Setting null filters');
      nullFilters.forEach(nf => {
        if (nf.boolValue) {
          nullString += `&${nf.varName}[ne]=$null`;
        } else {
          nullString += `&${nf.varName}=$null`;
        }
      });
      console.log(nullString);
    }
    if (booleanFilters.length > 0) {
      booleanFilters.forEach(bf => {
        boolString += `&${bf.varName}`;
        boolString += bf.boolValue ? '=True' : '=False';
      });
    }
    if (targetDateColumn !== '') {
      if (startDate !== '' && endDate !== '') {
        dateString = `&${targetDateColumn}[ge]=${new Date(startDate).toISOString().split('T')[0]}` +
        `&${targetDateColumn}[le]=${new Date(endDate).toISOString().split('T')[0]}`;
      } else if (startDate !== '' && endDate === '') {
        dateString = `&${targetDateColumn}[ge]=${new Date(startDate).toISOString().split('T')[0]}`;
      } else if (startDate === '' && endDate !== '') {
        dateString = `&${targetDateColumn}[le]=${new Date(endDate).toISOString().split('T')[0]}`;
      }
    }
    string = string.substr(0, string.length - 1);
    const httpHeaders = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress() + targetTable}?${string}&$sort=${sortBy}${include}&$page=${pageIndex}` +
      `&$per_page=${itemsPerPage}${dateString}${boolString}${nullString}${specialCondition}`,
      httpHeaders
    );
  }
}
