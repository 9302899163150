import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TestingComponent} from './testing.component';
import {TestingRoutingModule} from './testing-routing.module';
import {RouterModule} from '@angular/router';
import {MenuComponent} from '../core/utils/menu/menu.component';
import {MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule} from '@angular/material';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AppModule} from '../app.module';

@NgModule({
  declarations: [
    TestingComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    CollapseModule,
    FontAwesomeModule,
    MatMenuModule,
  ],
  exports: [
    TestingComponent,
    TestingRoutingModule,
    MenuComponent
  ]
})
export class TestingModule { }
