import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filtering-input',
  templateUrl: './filtering-input.component.html',
  styleUrls: ['./filtering-input.component.css']
})
export class FilteringInputComponent implements OnInit {
  filterList;
  userSelection = {valueToAccess: '', id: null};
  userInput = '';
  showList = false;
  @Input() set list (list: any[]) {
    this.optionsList = [...list];
    this.filterList = this.optionsList .slice(0);
  }
  @Input() valueToAccess: string;
  @Input()
  set preSelected(value: string) {
    this.userInput = value;
  }
  @Output() chosenItem = new EventEmitter<any>();

  optionsList = [];
  constructor() {
   }

  ngOnInit() {
    console.log(this.optionsList );
    console.log(this.userSelection);
  }

  userSelected(theChosen) {
    console.log('Selected');
    console.log(theChosen);
    this.userInput = String(theChosen[this.valueToAccess]);
    this.userSelection = {...theChosen};
    this.chosenItem.emit({...theChosen});
    this.showList = false;
  }

  filter() {
    const sampleList = this.optionsList .slice(0);
    console.log(sampleList);
    console.log(this.valueToAccess);
    const newFilterList = sampleList.filter(i => String(i[this.valueToAccess]).includes(this.userInput));
    if (newFilterList.length === 1) {
      this.userSelection = {...newFilterList[0]};
    }
    console.log(newFilterList);
    this.filterList = newFilterList;
  }

  mouseEnter() {
    this.showList = true;
   }

   mouseLeave() {
     this.showList = false;
   }

}
