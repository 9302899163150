import {Injectable} from '@angular/core';
import {Customer} from '../models/dbModels/customer.model';
import {EdiPreference} from '../models/dbModels/edi-preferences.model';
import {ValueTableLookupService} from './value-table-lookup.service';
import {Observable, of, Subject} from 'rxjs';
import {Product} from '../models/dbModels/product.model';
import {GetApiReply} from '../models/get-api-reply.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private lookupS: ValueTableLookupService,
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  customers: Customer[] = [];
  ediPreferences: EdiPreference[] = [];
  // ediPreferences: EdiPreference[] = [];
  products: Product[] = [];
  logo = '';
  companyDetails = null;
  private customerSource = new Subject<Customer[]>();
  private ediPreferenceSource = new Subject<EdiPreference[]>();
  // private ediPreferenceSource = new Subject<EdiPreference[]>();
  private productSource = new Subject<Product[]>();

  customers$ = this.customerSource.asObservable();
  products$ = this.productSource.asObservable();

  private handleError<T>(operation = 'operation', result?: T) {
    const httpOptions = this.getHttpOptions();
    return (error: any): Observable<T> => {
      console.error(error); // Replace with logging service later.
      console.log(`${operation} failed: ${error.message}`);
      console.log(httpOptions);
      console.log(localStorage.getItem('access_token'));
      return of(result as T);
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }

  setCustomer(customers: Customer[]) {
    this.customers = customers.slice(0);
    this.pushUpgradedCustomer(customers);
  }

  setEdiPreference(ediPreferences: EdiPreference[]) {
    this.ediPreferences = ediPreferences.slice(0);
    this.pushUpgradedEdiPreference(ediPreferences);
  }

  getCustomers() {
    this.lookupS.getCustomers().subscribe(res => {
      this.setCustomer(res.data);
    });
    // Random interval between 10 minutes and 15 minutes to stagger requests from apps to apis
    const interval = Math.floor((Math.random() * 900000) + 600000);
    setInterval(() => {
      this.lookupS.getCustomers().subscribe(res => {
        this.setCustomer(res.data);
      });
    }, interval);
  }

  pushUpgradedCustomer(customers: Customer[]) {
    this.customerSource.next(customers);
  }

  pushUpgradedEdiPreference(ediPreferences: EdiPreference[]) {
    this.ediPreferenceSource.next(ediPreferences);
  }

  addCustomer(customer: Customer) {
    this.customers.push(customer);
    this.pushUpgradedCustomer(this.customers);
  }

  addEdiPreference(ediPreference: EdiPreference) {
    this.ediPreferences.push(ediPreference);
    this.pushUpgradedEdiPreference(this.ediPreferences);
  }

  removeCustomerById(id: number) {
    const customers = this.customers.filter(cus => cus.id !== id);
    this.customers = customers.slice(0);
    this.pushUpgradedCustomer(this.customers);
  }

  updateCustomer(customer) {
    const index = this.customers.findIndex(cus => cus.id === customer.id);
    if (index !== -1) {
      this.customers[index] = customer;
      this.pushUpgradedCustomer(this.customers);
    } else {
      this.customers.push(customer);
    }
  }

  updateEdiPreference(ediPreference) {
    const index = this.ediPreferences.findIndex(edi => edi.id === ediPreference.id);
    if (index !== -1) {
      this.ediPreferences[index] = ediPreference;
      this.pushUpgradedEdiPreference(this.ediPreferences);
    } else {
      this.ediPreferences.push(ediPreference);
    }
  }


  setProduct(products: Product[]) {
    this.products = products.slice(0);
    this.pushUpgradedProduct(products);
  }

  getProducts() {
    this.lookupS.getProducts().subscribe(res => {
      this.setProduct(res.data);
    });
    // Random interval between 10 minutes and 15 minutes to stagger requests from apps to apis
    const interval = Math.floor((Math.random() * 900000) + 600000);
    setInterval(() => {
      this.lookupS.getProducts().subscribe(res => {
        this.setProduct(res.data);
      });
    }, interval);
  }

  pushUpgradedProduct(products: Product[]) {
    this.productSource.next(products);
  }

  addProduct(product: Product) {
    this.products.push(product);
    this.pushUpgradedProduct(this.products);
  }

  removeProductById(id: number) {
    const products = this.products.filter(prod => prod.id !== id);
    this.products = products.slice(0);
    this.pushUpgradedProduct(this.products);
  }

  updateProducts(product) {
    const index = this.products.findIndex(prod => prod.id === product.id);
    if (index !== -1) {
      this.products[index] = product;
      this.pushUpgradedProduct(this.products);
    } else {
      this.products.push(product);
    }
  }


  getCompanyDetails() {
    const httpOptions = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}organisations`,
      httpOptions
    );
  }

  setCompanyDetails() {
    this.getCompanyDetails().subscribe(res => {
      if (res.success) {
        this.companyDetails = res.data[0];
      }
    });
  }

  updateCompanyDetails(companyDetails) {
    this.companyDetails = companyDetails;
  }

  checkCompanyDetails() {
    if (this.companyDetails === null) {
      this.setCompanyDetails();
    }
  }

  getLocalCompanyDetails() {
    return {...this.companyDetails};
  }

  getCompanyCurrency() {
    if (this.companyDetails !== null) {
      return this.companyDetails.currency;
    } else {
      this.getCompanyDetails();
    }
    return null;
  }

  getCompanyCountry() {
    if (this.companyDetails !== null) {
      return this.companyDetails.country;
    }
    return null;
  }


}
