import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  private errorMessageSource = new Subject<string>();
  errorMessageSource$ = this.errorMessageSource.asObservable();

  missingDates = 'No dates selected';
  missingName = 'Missing Name';

  constructor() {
  }

  newErrorMessage(errorMessage: string) {
    if (errorMessage === 'dates') {
      console.log('No date selected');
    }
    this.errorMessageSource.next(errorMessage);
  }
}
