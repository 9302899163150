import {Component, OnInit, Renderer2} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {AdminService} from '../../../admin/admin.service';
import {OrganisationsServiceV2} from '../../services/salestrackServiceV2/crud-endpoints/organisations.service';
import {FaConfig, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {UserPermissionController} from '../../controllers/userPermissionsController';
import {NGXLogger} from 'ngx-logger';
import {OrganisationService} from '../../services/organisation.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [AdminService, FaConfig, FaIconLibrary, OrganisationsServiceV2, UserPermissionController]
})
export class MenuComponent implements OnInit {
  isCollapsed = true;
  subMenuOpen = false;
  faSignIn = faSignInAlt;
  faSignOut = faSignOutAlt;

  /*** Logo ***/
  base64textString = null;
  preferencesId = null;
  loaded = false;

  /*** User Data ***/

  id;
  name;
  email;
  username;
  tokenData = {
    email: '',
    username: ''
  };
  userData = null;

  modulesList: Array<any>;
  MenuItems: Array<any>;
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  prevButtonTrigger;

  perms = {
    salesItems: {
      sales: false,
      dockets: false,
    },
    orders: false,
    stockItems: {
      stock: false,
      stock_search: false
    },
    production: false,
    logisticsItems: {
      logistics: false,
      suppliers: false,
      delivery: false,
      dispatch: false,
    },
    financeItems: {
      finance: false,
      documents: false,
      discounts: false,
      exports: false,
      payments: false,
    },
    customers: true,
    services: false,
    products: true,
    adminItems: {
      admin: false,
      organisation_settings: false,
      financial_settings: false,
      customer_settings: false,
      product_settings: false,
      storage_settings: false,
      handheld_settings: false,
      other_settings: false
    },
    reportItems: {
      admin: false,
      organisation_settings: false,
      financial_settings: false,
      customer_settings: false,
      product_settings: false,
      storage_settings: false,
      handheld_settings: false,
      other_settings: false
    },
    dashboard: false
  };

  // userPerms: any = {};
  userPerms = null;

  // Process
  menuSales = {
    label: 'Sales',
    icon: 'fa-shopping-cart',
    category: 'process',
    role: false,
    children: [{
      label: 'Dockets',
      perms: true,
      children: [{
        label: 'Dockets',
        link: '/transactions/dockets',
        perms: false
      }, {
        label: 'Docket Reprint',
        link: '/transactions/docket-reprint',
        perms: false
      }]
    }]
  };
  menuOrders: any = {};
  menuProduction = {
    label: 'Production',
    icon: 'fa-industry',
    category: 'process',
    role: false,
    children: [{
      label: 'Production List',
      link: '/production/production-lists'
    }, {
      label: 'Create Production Lists'
    }, {
      label: 'Production Groups'
    }, {
      label: 'Production Inputs'
    }, {
      label: 'Production Process'
    }, {
      label: 'Batch Control'
    }]
  };
  menuStock = {
    label: 'Stock',
    icon: 'fa-cubes',
    category: 'process',
    role: false,
    children: [{
      label: 'Stock Search',
      link: '/inventory/advanced-stock-search'
    }, {
      label: 'Stock Location',
      link: '/inventory/stock-location'
    }, {
      label: 'Stock Level Alert',
      link: '/inventory/stock-count-alert'
    }]
  };
  menuLogistics = {
    label: 'Logistics',
    icon: 'fa-truck',
    category: 'process',
    role: false,
    children: [{
      label: 'Suppliers',
      children: [{
        label: 'Suppliers List',
        link: '/logistics/suppliers-list',
        perms: false
      }]
    }, {
      label: 'Delivery',
      children: [{
        label: 'Delivery Lists',
        link: '/logistics/delivery-lists',
        perms: false
      }, {
        label: 'Create Delivery Lists',
        link: '/logistics/delivery-lists/create',
        perms: false
      }]
    }, {
      label: 'Dispatch',
      children: [{
        label: 'Dispatch Groups',
        link: '/logistics/dispatch-group',
        perms: false
      }, {
        label: 'Create Dispatch Lists',
        link: '/logistics/new-dispatch-list',
        perms: false
      }, {
        label: 'Edit Dispatch Lists',
        link: '/logistics/manage-dispatch-list',
        perms: false
      }]
    }]
  };
  menuFinance = {
    label: 'Finance',
    icon: 'fa-coins',
    category: 'process',
    role: false,
    children: [{
      label: 'Documents',
      children: [{
        label: 'Create Invoices',
        link: '/transactions/invoice-creation',
        perms: false
      }, {
        label: 'View Invoices',
        link: '/transactions/invoices',
        perms: false
      }, {
        label: 'Statements',
        link: '/transactions/statements',
        perms: false
      }, {
        label: 'Credit Notes',
        link: '/transactions/credit-notes',
        perms: false
      }, {
        label: 'Export Invoices',
        link: '/transactions/invoice-export',
        perms: false
      }]
    }, {
      label: 'Discounts',
      children: [{
        label: 'Create Discounts',
        link: '/transactions/invoice-creation'
      }, {
        label: 'View Discounts',
        link: '/transactions/invoices'
      }, {
        label: 'Discounts',
        link: '/transactions/statements'
      }, {
        label: 'Credit Discounts',
        link: '/transactions/credit-notes'
      }, {
        label: 'Export Discounts',
        link: '/transactions/invoice-export'
      }]
    }, {
      label: 'Exports',
      children: [{
        label: 'Create Invoices',
        link: '/transactions/invoice-creation'
      }, {
        label: 'View Invoices',
        link: '/transactions/invoices'
      }, {
        label: 'Statements',
        link: '/transactions/statements'
      }, {
        label: 'Credit Notes',
        link: '/transactions/credit-notes'
      }, {
        label: 'Export Invoices',
        link: '/transactions/invoice-export'
      }]
    }]
  };
  // Assignment
  menuCustomers = {
    label: 'Customers',
    icon: 'fa-user',
    category: 'assignment',
    role: true,
    children: [{
      label: 'Customers',
      link: '/customers/customers-list'
    }, {
      label: 'Customer Group Update',
      link: '/customers/customer-group-update'
    }, {
      label: 'Customer on route',
      link: '/customers/customers-on-route'
    }, {
      label: 'Customer Specific Prices',
      link: '/customers/customer-specific-prices'
    }, {
      label: 'Customer Specific Price Group Update',
      link: '/customers/customer-specific-price-group-update'
    }, {
      label: 'Inactive Customers',
      link: '/admin/customer-settings/inactive-customers'
    }]
  };
  menuProducts = {
    label: 'Products',
    icon: 'fa-boxes',
    category: 'assignment',
    role: true,
    children: [{
      label: 'Products',
      link: '/products/products-list'
    }, {
      label: 'Multiple Product Update',
      link: '/products/mass-product-update'
    }, {
      label: 'Price List',
      link: '/products/product-price-list'
    }, {
      label: 'Multiple Price Update',
      link: '/products/mass-price-update'
    }, {
      label: 'Inactive Products',
      link: '/admin/product-settings/inactive-products'
    }]
  };
  menuServices = {
    label: 'Services',
    icon: 'fa-concierge-bell',
    category: 'assignment',
    role: false,
    children: [{
      label: 'Service List',
      link: '/services/services-list'
    }, {
      label: 'Create Service Form',
      link: '/orders/service-orders/create'
    }]
  };
  // Settings
  menuAdmin = {
    label: 'Admin',
    icon: 'fa-user-cog',
    category: 'settings',
    role: true,
    children: [{
      label: 'Organisation Settings',
      children: [{
        label: 'Company Details',
      }, {
        label: 'Billing Details',
      }, {
        label: 'Logo Upload',
      }]
    }, {
      label: 'Financial Settings',
      children: [{
        label: 'Bank Details',
      }, {
        label: 'Account Details',
      }, {
        label: 'Vat Rates',
      }]
    }, {
      label: 'Customer Settings',
      children: [{
        label: 'Depots',
        link: ''
      }, {
        label: 'Customer Types',
        link: ''
      }, {
        label: 'Routes',
        link: ''
      }, {
        label: 'Billing Frequency',
        link: ''
      }]
    }, {
      label: 'Product Settings',
      children: [{
        label: 'Product Categories',
        link: '/admin/product-settings/product-categories'
      }, {
        label: 'Sub Product Categories',
        link: '/admin/product-settings/sub-product-categories'
      }, {
        label: 'Quantifiers',
        link: '/admin/product-settings/quantifiers'
      }, {
        label: 'Create Nominal Codes',
        link: '/admin/organisation-settings/account-package-settings/nominal-codes'
      }]
    }, {
      label: 'Storage Settings',
      children: [{
        label: 'Storage Locations',
        link: '/admin/storage-settings/storage-locations'
      }, {
        label: 'Storage Location Types',
        link: 'admin/storage-settings/storage-location-types'
      }, {
        label: 'Storage Areas',
        link: 'admin/storage-settings/storage-areas'
      }, {
        label: 'Storage Area Types',
        link: 'admin/storage-settings/storage-area-types'
      }]
    }, {
      label: 'Handheld Settings',
    }, {
      label: 'Other Settings',
      children: [{
        label: 'EDI Settings',
        link: '/admin/other/edi-settings'
      }, {
        label: 'Commissions',
        link: '/admin/commissions'
      }]
    }]
  };
  menuTraceability = {
    label: 'Traceability',
    icon: 'fa-boxes',
    category: 'settings',
    role: true,
    children: [{
      label: 'Barcodes',
      link: '/interaction/barcodes'
    }]
  };
  // Performance
  menuReports = {
    label: 'Reports',
    icon: 'fa-chart-line',
    category: 'performance',
    role: true,
    children: [{
      label: 'Sales Reports',
      children: [{
        label: 'Create'
      }, {
        label: 'Saved',
        link: 'reports-old/sales-reports/saved-sales-report'
      }, {
        label: 'Comparison'
      }, {
        label: 'Summary'
      }]
    }, {
      label: 'Production Reports',
      children: [{
        label: 'Create',
        link: 'reports/production-reports/generate-production-report'
      }, {
        label: 'Saved',
        link: ''
      }, {
        label: 'Comparison',
        link: ''
      }, {
        label: 'Summary',
        link: ''
      }]
    }, {
      label: 'Servicing Reports',
      children: [{
        label: 'Create',
        link: 'reports/service-reports/generate-service-report'
      }]
    }, {
      label: 'Stock Reports',
      children: [{
        label: 'Create',
        link: 'reports/stock-reports/generate-stock-report'
      }]
    }, {
      label: 'Financial Reports',
      children: [{
        label: 'Create',
        link: ''
      }, {
        label: 'Saved',
        link: ''
      }, {
        label: 'Comparison',
        link: ''
      }, {
        label: 'Summary',
        link: ''
      }]
    }, {
      label: 'Agent Commission Reports',
      link: ''
    }]
  };
  menuDashboard = {
    label: 'Dashboard',
    icon: 'fa-chart-pie',
    category: 'performance',
    role: true,
    children: [{
      label: 'Sales',
      link: '/dashboard'
    }]
  };

  menuItems: any = [];
  user_image: SafeResourceUrl;

  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private adminS: AdminService,
    private orgS: OrganisationService,
    private sanitizer: DomSanitizer,
    private ren: Renderer2,
    private organisationsServiceV2: OrganisationsServiceV2,
    private userPermissionsController: UserPermissionController
  ) {
    this.MenuItems = this.menuItems;
  }

  ngOnInit() {
    this.loadNavData();
  }

  setDefaults() {
    this.userPerms.orders.standing_orders = false;
    this.userPerms.orders.special_orders = false;
    this.userPerms.orders.service_orders = false;
  }

  orderItems() {
    // Hiding single level menu items
    let j = 0;
    for (let i = 0; i < this.menuOrders.children.length && this.menuOrders.children[i].perms === false; i++) {
      j++;
      // console.log(this.menuOrders.children[i].label + ' is false (' + j + ')');
    }
    if (this.menuOrders.children.length === j) {
      this.menuOrders.perms = false;
    }
  }

  loadNavData() {
    if (this.auth.isAuthenticated()) {
      this.getData();
      this.getLogo();
      this.getUserData();
    } else {
      setTimeout(() => this.loadNavData(), 1000);
    }
  }

  getData() {
    this.organisationsServiceV2.getMyOrgId().subscribe(
      res => {
        const id = res.body;
        this.logger.info('Loading organisation ID:', id);
        let optionalParams = 'select=*';
        optionalParams += '&id=eq.' + id;
        this.organisationsServiceV2.getOrganisations(optionalParams).subscribe(
          data => {
            this.logger.info('Loading organisation data for', data.body[0].name);
            const item = data.body[0];
            this.id = item.id;
            this.name = item.name;
          },
          err => {
            this.logger.error('Received error:', err);
          },
          () => {
            this.logger.info('Process complete, closing request!');
          }
        );
      }, err => {
        this.logger.error('Received error:', err);

      },
      () => {
        this.logger.info('Process complete, closing request!');
      });
  }

  getLogoOld() {
    this.adminS.getItems('logos').subscribe((res: any) => {
        // console.log(res); // Hiding navigation array
        if (res.data.length > 0) {
          this.base64textString = `data:image/png;base64,${res.data[0].bytea_value}`;
          // console.log(this.base64textString); // Hiding logo console output
          this.preferencesId = res.data[0].id;
          this.loaded = true;
        } else {
          this.loaded = true;
        }
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  getLogo() {
    const optionalParams = 'order=id';
    this.orgS.getLogo().subscribe(
      data => {
        this.logger.info('Loading organisation logos:', data);
        // data = '';
        if (data !== '') {
          this.base64textString = 'data:image/png;base64,' + data;
          this.base64textString = this.sanitizer.bypassSecurityTrustUrl(this.base64textString);
          this.logger.info('Logo: ' + this.base64textString);
        }
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  getUserData() {
    this.auth.getUserInfo().subscribe(
      (data: any) => {
        // Get users email
        this.tokenData.email = data.email;
        this.email = data.email;
        // Get username
        this.tokenData.username = data['https://api.hhsalestrack.com/user_metadata'].username;
        this.getUserPermsNew().then(r => {
        });
        this.getUserPerms();
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  getUserPerms() {
    // Get the email id from the users table.
    // Get the user_roles data by joining the table by the user id and the user_roles user_id (This is a FK in the db)
    // In the joined user_roles table do another joing to get the roles data (Another FK in the db)
    let optionalParams = 'select=email,id,user_roles(id,roles(name))';
    optionalParams += '&name=eq.' + this.tokenData.username;
    // Get user data
    this.auth.getUsers(optionalParams).subscribe(
      data => {
        this.userData = data;
        // this.setPermissions();
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  setLogic() {
    /** Sales */
    if (this.userPerms.sales.dockets.dockets === true || this.userPerms.sales.dockets.docket_reprint === true) {
      this.perms.salesItems.dockets = true;
    }
    if (this.userPerms.sales.dockets.dockets === true || this.userPerms.sales.dockets.docket_reprint === true) {
      this.perms.salesItems.sales = true;
    }

    /** Orders */
    if (this.userPerms.orders.standing_orders === true || this.userPerms.orders.special_orders === true
      || this.userPerms.orders.service_orders === true) {
      this.perms.orders = true;
    }
    /** logistics */
    // suppliers
    if (this.userPerms.logistics.suppliers.suppliers_list === true) {
      this.perms.logisticsItems.suppliers = true;
    }
    // delivery
    if (this.userPerms.logistics.delivery.delivery_lists === true || this.userPerms.logistics.delivery.create_delivery_list === true) {
      this.perms.logisticsItems.delivery = true;
    }
    // dispatch
    if (this.userPerms.logistics.dispatch.dispatch_groups === true || this.userPerms.logistics.dispatch.create_dispatch_lists === true ||
      this.userPerms.logistics.dispatch.edit_dispatch_lists === true) {
      this.perms.logisticsItems.dispatch = true;
    }
    // Set menu item
    if (this.perms.logisticsItems.suppliers === true || this.perms.logisticsItems.delivery === true
      || this.perms.logisticsItems.dispatch === true) {
      this.perms.logisticsItems.logistics = true;
    }
    /** Stock */
    if (this.userPerms.stock.stock_search.production_location === true || this.userPerms.stock.stock_search.location_search === true ||
      this.userPerms.stock.stock_search.storage_area_search || this.userPerms.stock.stock_search.stock_location === true ||
      this.userPerms.stock.stock_search.stock_level_alert === true) {
      this.perms.stockItems.stock = true;
    }
    if (this.userPerms.stock.stock_search.production_location === true || this.userPerms.stock.stock_search.location_search === true ||
      this.userPerms.stock.stock_search.storage_area_search) {
      this.perms.stockItems.stock_search = true;
    }
    /** Production */
    if (this.userPerms.production.production_list === true || this.userPerms.production.create_production_list === true ||
      this.userPerms.production.production_groups === true || this.userPerms.production.production_inputs === true ||
      this.userPerms.production.production_process === true || this.userPerms.production.batch_control === true) {
      this.perms.production = true;
    }
    /** Finance */
    // documents
    if (this.userPerms.finance.documents.create_invoice === true || this.userPerms.finance.documents.view_invoice === true ||
      this.userPerms.finance.documents.create_statements === true || this.userPerms.finance.documents.create_credit_notes === true) {
      this.perms.financeItems.documents = true;
    }
    // discounts
    if (this.userPerms.finance.discounts.product_discounts === true || this.userPerms.finance.discounts.customer_discounts === true ||
      this.userPerms.finance.discounts.product_quantity_offers === true || this.userPerms.finance.discounts.bulk_discounts === true ||
      this.userPerms.finance.discounts.billing_discounts === true) {
      this.perms.financeItems.discounts = true;
    }
    // exports
    if (this.userPerms.finance.payments.incoming === true || this.userPerms.finance.payments.outgoing === true) {
      this.perms.financeItems.payments = true;
    }
    // Payments
    if (this.userPerms.finance.exports.export_invoices === true || this.userPerms.finance.exports.review_invoices === true ||
      this.userPerms.finance.exports.customer_exports === true || this.userPerms.finance.exports.product_exports === true ||
      this.userPerms.finance.exports.edi_exports === true || this.userPerms.finance.exports.custom_exports === true) {
      this.perms.financeItems.exports = true;
    }
    // Set finance menu item
    if (this.perms.financeItems.documents === true ||
      this.perms.financeItems.discounts === true ||
      this.perms.financeItems.exports === true ||
      this.perms.financeItems.payments === true
    ) {
      this.perms.financeItems.finance = true;
    }
    /** Services */
    if (this.userPerms.services.service_list === true ||
      this.userPerms.services.job_report_builder === true ||
      this.userPerms.services.job_reports === true ||
      this.userPerms.services.create_service_form === true) {
      this.perms.services = true;
    }

    /** Admin Items */
    // organisation_settings
    if (this.userPerms.admin.organisation_settings.company_details === true ||
      this.userPerms.admin.organisation_settings.account_package_setup === true ||
      this.userPerms.admin.organisation_settings.account_package_settings === true ||
      this.userPerms.admin.organisation_settings.billing_details === true ||
      this.userPerms.admin.organisation_settings.logo_upload === true) {
      this.perms.adminItems.organisation_settings = true;
    }
    // financial_settings
    if (this.userPerms.admin.financial_settings.bank_details === true ||
      this.userPerms.admin.financial_settings.account_details === true ||
      this.userPerms.admin.financial_settings.vat_rates === true) {
      this.perms.adminItems.financial_settings = true;
    }
    // customer_settings
    if (this.userPerms.admin.customer_settings.depots === true ||
      this.userPerms.admin.customer_settings.customer_types === true ||
      this.userPerms.admin.customer_settings.routes === true ||
      this.userPerms.admin.customer_settings.billing_frequency === true) {
      this.perms.adminItems.customer_settings = true;
    }
    // product_settings
    if (this.userPerms.admin.product_settings.product_categories === true ||
      this.userPerms.admin.product_settings.sub_product_categories === true ||
      this.userPerms.admin.product_settings.quantifiers === true ||
      this.userPerms.admin.product_settings.create_nominal_codes === true) {
      this.perms.adminItems.product_settings = true;
    }
    // storage_settings
    if (this.userPerms.admin.storage_settings.storage_locations === true ||
      this.userPerms.admin.storage_settings.storage_location_types === true ||
      this.userPerms.admin.storage_settings.storage_areas === true ||
      this.userPerms.admin.storage_settings.storage_area_types === true) {
      this.perms.adminItems.storage_settings = true;
    }
    // handheld_settings
    if (this.userPerms.admin.handheld_settings.create_handhelds === true ||
      this.userPerms.admin.handheld_settings.assign_handhelds_to_routes === true ||
      this.userPerms.admin.handheld_settings.assign_handhelds_to_routes === true) {
      this.perms.adminItems.handheld_settings = true;
    }
    // other_settings
    if (this.userPerms.admin.other_settings.edi_settings === true ||
      this.userPerms.admin.other_settings.commissions === true) {
      this.perms.adminItems.other_settings = true;
    }
    if (this.userPerms.dashboard.sales === true) {
      this.perms.dashboard = true;
    }
    // Set admin menu item
    if (
      this.perms.adminItems.organisation_settings === true ||
      this.perms.adminItems.financial_settings === true ||
      this.perms.adminItems.customer_settings === true ||
      this.perms.adminItems.product_settings === true ||
      this.perms.adminItems.storage_settings === true ||
      this.perms.adminItems.handheld_settings === true ||
      this.perms.adminItems.other_settings === true) {
      this.perms.adminItems.admin = true;
    }
  }

  async getUserPermsNew() {
    const userPermissions = await this.userPermissionsController.getUsers(null, null, [this.tokenData.username]);
    this.userPerms = userPermissions[0].user_permissions;
    // this.setDefaults();
    this.setLogic();
  }

  setPermissions() {
    // Check if the user has the permission by filtering the list of permissions by the role name.
    // Best way to do it since we can't guarantee ids will the consistent across the dbs
    this.menuSales.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'transactions').length === 1);
    this.menuOrders.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'orders').length === 1);
    this.menuProduction.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'production').length === 1);
    this.menuStock.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'inventory').length === 1);
    this.menuLogistics.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'logistics').length === 1);
    this.menuFinance.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'finance').length === 1);
    this.menuServices.role = (this.userData[0].user_roles.filter(a => a.roles.name === 'finance').length === 1);

    /** depreciated
     this.roleTransactions = (this.userData[0].user_roles.filter(a => a.roles.name === 'transactions').length === 1);
     this.roleOrders = (this.userData[0].user_roles.filter(a => a.roles.name === 'orders').length === 1);
     this.roleProduction = (this.userData[0].user_roles.filter(a => a.roles.name === 'production').length === 1);
     this.roleInventory = (this.userData[0].user_roles.filter(a => a.roles.name === 'inventory').length === 1);
     this.roleLogistics = (this.userData[0].user_roles.filter(a => a.roles.name === 'logistics').length === 1);
     this.roleFinance = (this.userData[0].user_roles.filter(a => a.roles.name === 'finance').length === 1);
     // Assignment
     this.roleCustomers = true;
     this.roleProducts = true;
     // Settings
     this.roleAdmin = true;
     this.roleInteraction = false;
     // Performance
     this.roleReports = true;
     this.roleDashboard = true;
     */
  }

  checkRoles() {
    console.log('---------------------');
    console.log('User ' + this.tokenData.email + ' has the following roles:');
    // console.log(this.userData[0].user_roles);
    // console.log(this.userData[0].user_roles.length);
    for (let i = 0; i < this.userData[0].user_roles.length; i++) {
      console.log(this.userData[0].user_roles[i].roles.name);
    }
    console.log('---------------------');
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    console.log(this.isCollapsed);
  }

  menuEnter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100);
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  dynamicMenu() {
    // for (let i = 0; i < this.menuSales.children.length; i++) {
    //   console.log('child1: ' + this.menuSales.children.length);
    //   console.log('child1: ' + this.menuSales.children[i].label);
    //   for (let j = 0; j < this.menuSales.children[i].children.length; j++) {
    //   }
    //   let k = 0;
    //   for (let j = 0; j < this.menuSales.children[i].children.length && this.menuSales.children[i].children[j].perms === false; j++) {
    //     k++;
    //     console.log(this.menuSales.children[i].children[j].label + ' is false (' + k + ')');
    //   }
    //   if (this.menuSales.children[i].children.length === k) {
    //     this.menuSales.children[i].perms = false;
    //     console.log('Hide');
    //   }
    // }
    // this.menuItems = [
    //   // Process
    //   this.menuSales,
    //   this.menuOrders,
    //   this.menuProduction,
    //   this.menuStock,
    //   this.menuLogistics,
    //   this.menuFinance,
    //   // Assignment
    //   this.menuCustomers,
    //   this.menuServices,
    //   this.menuProducts,
    //   // Settings
    //   this.menuAdmin,
    //   this.menuTraceability,
    //   // Performance
    //   this.menuReports,
    //   this.menuDashboard,
    //   {
    //     label: 'Sales',
    //     icon: 'fa-user',
    //     category: 'process',
    //     children: [{
    //       label: 'Dockets',
    //       children: [{
    //         label: 'Dockets',
    //         link: '/transactions/dockets'
    //       }, {
    //         label: 'Docket Reprint',
    //         link: '/transactions/docket-reprint'
    //       }]
    //     }, {
    //       label: 'Invoices'
    //     }, {
    //       label: 'Other',
    //       children: [{
    //         label: 'Statements'
    //       }, {
    //         label: 'Credit Notes'
    //       }, {
    //         label: 'Agent Commissions'
    //       }]
    //     }]
    //   },
    // ];
    // this.orderItems();
  }

}

export interface NavItem {
  text: string;
  icon: string;
  category: string;
  href?: string;
  hrefTarget?: string;
  subItems?: NavItem[];
}

