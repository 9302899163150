import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import jsQR, {QRCode} from 'jsqr';
import {MatDialog} from '@angular/material';
import {DialogComponent} from '../dialog/dialog.component';
import {NGXLogger} from 'ngx-logger';
import {InventoryService} from '../../services/inventory.service';
import {ProductionService} from '../../services/production.service';
import {LogisticsService} from '../../services/logistics.service';
import {AlertService} from '../../services/alert.service';
import {IncomingDeliveryList} from '../../models/dbModels/incoming_delivery_lists.model';
import * as moment from 'moment';
import {Howl} from 'howler';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.css'],
  providers: [ProductionService]
})
export class QrCodeScannerComponent implements OnInit {
  @ViewChild('video', {static: true}) videoElm: ElementRef;
  @ViewChild('canvas', {static: true}) canvasElm: ElementRef;

  videoStart = false;
  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };

  suppliers = [];
  selectedValue: '';
  supplier: any = {};
  supplierSelected = false;
  supplierId;

  scannedVouchers = [];

  autoBatch = false;
  locationId: number;
  storageAreaId: number;

  constructor(
    private logger: NGXLogger,
    private alert: AlertService,
    private inventoryS: InventoryService,
    private prodS: ProductionService,
    private logisticsS: LogisticsService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.locationsCheck();
    this.getSuppliers();
  }

  // Get lookups

  async locationsCheck() {
    // Check for existing location
    const optionalParams = `name=ilike.*Closed Vouchers*`;
    await this.inventoryS.getLocations(optionalParams).toPromise().then(
      async promiseData => {
        this.logger.log('Loading promise data:', promiseData);
        if (promiseData.body.length === 0) {
          // If it doesn't, create a new one
          this.logger.log('No location found');
          const location = {
            name: 'Closed Vouchers',
            code: 'Closed',
          };
          await this.inventoryS.createLocation([location]).toPromise().then(
            locCreationRes => {
              this.logger.log('Loading promise data:', locCreationRes);
            });
        } else {
          this.logger.log('Location found:', promiseData.body[0].name);
          this.locationId = promiseData.body[0].id;
        }
      },
      err => {
        this.logger.error('Received error:', err);
      }
    );
    // Check for existing storage area
    const saParams = `name=ilike.*Closed Voucher Storage*`;
    await this.inventoryS.getStorageAreas(saParams).toPromise().then(
      async promiseData => {
        this.logger.log('Loading promise data:', promiseData);
        if (promiseData.body.length === 0) {
          this.logger.log('No storage area found');
          // If it doesn't, create a new one
          const storageArea = {
            name: 'Closed Voucher Storage',
            code: 'CVS',
            location_id: this.locationId,
            storage_area_type_id: 1
          };
          await this.inventoryS.createStorageArea([storageArea]).toPromise().then(
            saCreationRes => {
              this.logger.log('Loading promise data:', saCreationRes);
            });
        } else {
          this.logger.log('Storage area found:', promiseData.body[0].name);
          this.storageAreaId = promiseData.body[0].id;
        }
      }
    );
    // this.inventoryS.getLocations(optionalParams).subscribe(
    //   data => {
    //     this.logger.info('Loading item data:', data.body);
    //     // Check to see if the preference exists already
    //     if (data.body.length === 0) {
    //       // If it doesn't, create a new one
    //       this.logger.log('No location found');
    //       const location = {
    //         name: 'Closed Vouchers',
    //         code: 'Closed',
    //       };
    //       this.inventoryS.createLocation([location]).subscribe(
    //         result => {
    //           this.logger.log('Loading item data:', result);
    //         },
    //         err => {
    //           this.logger.error('Received error:', err);
    //         },
    //         () => {
    //           this.logger.info('Process complete, closing request!');
    //         }
    //       );
    //     } else {
    //       // this.logger.log('Location found:', data.body[0].name);
    //       // this.locationId = data.body[0].id;
    //     }
    //   },
    //   err => {
    //     this.logger.error('Received error:', err, 'Search failed');
    //   },
    //   () => {
    //     this.logger.info('Process complete, closing request!');
    //     // Check for storage area
    //     this.inventoryS.getStorageAreas(saParams).subscribe(
    //       data => {
    //         this.logger.info('Loading item data:', data.body);
    //         // Check to see if the preference exists already
    //         if (data.body.length === 0) {
    //           this.logger.log('No storage area found', data.body);
    //           // If it doesn't, create a new one
    //           const storageArea = {
    //             name: 'Closed Voucher Storage',
    //             code: 'CVS',
    //             location_id: this.locationId,
    //             storage_area_type_id: 1
    //           };
    //           // if not create a new one
    //           this.inventoryS.createStorageArea([storageArea]).subscribe(
    //             result => {
    //               this.logger.log('Loading item data:', result);
    //             },
    //             err => {
    //               this.logger.error('Received error:', err);
    //             },
    //             () => {
    //               this.logger.info('Process complete, closing request!');
    //             }
    //           );
    //         } else {
    //           // this.logger.log('Storage area found:', data.body[0].name);
    //           // this.storageAreaId = data.body[0].id;
    //         }
    //       },
    //       err => {
    //         this.logger.error('Received error:', err, 'Search failed');
    //       },
    //       () => {
    //         this.logger.info('Process complete, closing request!');
    //       }
    //     );
    //   }
    // );
  }

  getSuppliers() {
    let optionalParams = 'select=*,country:countries(*)';
    optionalParams += '&order=name';
    this.logisticsS.getSuppliers(optionalParams).subscribe(
      data => {
        this.logger.log('Loading supplier data:', data.body);
        this.suppliers = data.body;
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  selectedSupplier(supplier) {
    this.supplier = supplier;
    this.logger.log(this.supplier.name, 'selected for voucher assignment');
    this.supplierSelected = true;
  }

  checkImage() {
    const WIDTH = this.videoElm.nativeElement.clientWidth;
    const HEIGHT = this.videoElm.nativeElement.clientHeight;
    this.canvasElm.nativeElement.width = WIDTH;
    this.canvasElm.nativeElement.height = HEIGHT;

    const ctx = this.canvasElm.nativeElement.getContext('2d') as CanvasRenderingContext2D;

    ctx.drawImage(this.videoElm.nativeElement, 0, 0, WIDTH, HEIGHT);
    const imageData = ctx.getImageData(0, 0, WIDTH, HEIGHT);
    const code = jsQR(imageData.data, imageData.width, imageData.height, {inversionAttempts: 'dontInvert'});

    if (code) {
      // this.openDialog(code);
      this.logger.info('QR Code:', code.data);
      this.applyVouchers(code);
    } else {
      setTimeout(() => {
        this.checkImage();
      }, 100);
    }
  }

  applyVouchers(code) {
    const scannedVouchers = [];
    this.logger.log('QR Code:', code);
    // Get product
    let voucher: any = {};
    let optionalParams = 'select=id,batch_id,batch:batches!batch_id(id,name),storage_area_id,serial_no';
    optionalParams += `,product_id,product:products!product_id(id,name)`;
    optionalParams += `&serial_no=ilike.*${code.data}*`;
    this.prodS.getBatchProducts(optionalParams).subscribe(
      data => {
        this.logger.log('Loading item data:', data.body[0]);
        voucher = data.body[0];
      },
      err => {
        this.logger.error('Received error:', err);
      },
      () => {
        this.logger.info('Process complete, closing request!');
        if (voucher.storage_area_id === this.storageAreaId) {
          this.logger.error('Voucher has already been redeemed');
          this.alert.message('Voucher has already been redeemed', 'danger');
          setTimeout(() => {
            this.checkImage();
          }, 5000);
        } else {
          this.logger.log('Voucher product:', voucher.product.name);
          this.logger.log('Voucher product id:', voucher.product_id);

          const index = this.scannedVouchers.findIndex(object => object.product_id === voucher.product_id);

          if (index === -1) {
            // If voucher product isn't on the list add it
            this.logger.log('Adding voucher');
            this.scannedVouchers.push(
              {
                'product_id': voucher.product_id,
                'product_name': voucher.product.name,
                'quantity': 1,
                'voucher_items': [{id: voucher.id}, {serial_no: voucher.serial_no}]
              }
            );
          } else {
            // If the voucher product is on the list, increase the quantity by 1.
            this.logger.log('Voucher already exists', voucher.product.name);
            this.scannedVouchers[index].quantity = this.scannedVouchers[index].quantity + 1;
            // Also add the serial number
            this.scannedVouchers[index].voucher_items.push({id: voucher.id}, {serial_no: voucher.serial_no});
          }

          this.logger.log('this.scannedVouchers:', this.scannedVouchers);

          // Play sound
          this.successSound().play();
          this.openDialog(code);
        }
      }
    );
    // Add to list
  }

  openDialog(code: QRCode): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '360px',
      data: {qrcode: code}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.checkImage();
    });
  }

  async submitVouchers() {
    // Create the  delivery list
    let deliveryListId;
    const createdList = await this.createDeliveryList();
    this.logger.log('Loading item data:', createdList);
    if (createdList.ok) {
      this.logger.log('Delivery list created');
      this.logger.log('Loading headers:', createdList.headers.get('location').split('.')[1]);
      deliveryListId = createdList.headers.get('location').split('.')[1];
    } else {
      this.logger.error('Received error:', createdList.message);
    }

    // Create the delivery list Items
    for (const voucher of this.scannedVouchers) {
      this.logger.log('Voucher:', voucher);
      // Update batch product
      let deliveryListItemId;
      const createdListItem = await this.createDeliveryListItem(deliveryListId, voucher);
      this.logger.log('Loading item data:', createdListItem);
      if (createdListItem.ok) {
        this.logger.log('Delivery list item created');
        this.logger.log('Loading headers:', createdListItem.headers.get('location').split('.')[1]);
        deliveryListItemId = createdListItem.headers.get('location').split('.')[1];
        // Create the delivery list Item products
        for (const voucherItem of voucher.voucher_items) {
          const createdListItemProduct = await this.createDeliveryListItemProduct(deliveryListItemId, voucherItem);
          this.logger.log('Loading item data:', createdListItemProduct);
        }
      } else {
        this.logger.error('Received error:', createdListItem.message);
      }
    }
  }

  async createDeliveryList() {
    const supplierName = this.supplier.name.split(' ').join('_');
    const internal_reference = `${moment().format('YYYY-MM-DD_HH-MM')}_${supplierName}_delivery_list`;
    const supplier_reference = `${this.supplier.code}_${moment().format('YYYY-MM-DD_HH-MM')}`;
    const list = new IncomingDeliveryList(
      this.supplier.id,
      supplier_reference,
      internal_reference,
      moment().toISOString(),
      this.storageAreaId,
      '',
      true
    );
    let headers;
    await this.logisticsS.createDeliveryList([list]).toPromise().then(
      promiseData => {
        this.logger.log('Loading promise data:', promiseData);
        headers = promiseData;
      });
    return headers;
  }

  async createDeliveryListItem(deliveryListId, voucher) {
    let headers;
    const payload = {
      'incoming_delivery_list_id': deliveryListId,
      'product_id': voucher.product_id,
      'actual_quantity': voucher.quantity
    };
    await this.logisticsS.createDeliveryListItem(payload).toPromise()
      .then(promiseData => {
        this.logger.log('Loading promise data:', promiseData);
        headers = promiseData;
      });
    return headers;
  }

  async createDeliveryListItemProduct(deliveryListItemId, voucherItem) {
    const payload = {
      'incoming_delivery_list_item_id': deliveryListItemId,
      'serial_no': voucherItem.serial_no,
    };
    this.logisticsS.createDeliveryListItemProduct(payload).subscribe(
      data => {
        this.logger.log('Loading item data:', data);
        // this.updateBatchProductLocation(serialNo);
      },
      err => {
        this.logger.error('Received error:', err);
        this.alert.message(err.message, 'danger');
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  updateBatchProductLocation(id) {
    const payload = {
      storage_area_id: this.storageAreaId,
    };
    this.prodS.updateBatchProduct(payload, id).subscribe(
      data => {
        this.logger.log('Loading item data:', data);
      },
      err => {
        this.logger.error('Received error:', err);
        this.alert.message(err.message, 'danger');
      },
      () => {
        this.logger.info('Process complete, closing request!');
      }
    );
  }

  // Scanner controls

  toggleVideoMedia() {
    if (this.videoStart) {
      this.stopVideo();
    } else {
      this.startVideo();
    }
    // this.videoStart ? this.stopVideo() : this.startVideo()
  }

  startVideo() {
    this.medias.video = true;
    navigator.mediaDevices.getUserMedia(this.medias).then(
      (localStream: MediaStream) => {
        this.videoElm.nativeElement.srcObject = localStream;
        this.videoStart = true;
        this.checkImage();
      }
    ).catch(
      error => {
        console.error(error);
        this.videoStart = false;
      }
    );
  }

  stopVideo() {
    this.medias.video = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].enabled = false;
    this.videoElm.nativeElement.srcObject.getVideoTracks()[0].stop();
    this.videoStart = false;
  }

  successSound() {
    return new Howl({
      src: ['assets/sounds/success.mp3']
    });
  }

}
