import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RouterModule, Routes} from '@angular/router';
import {CallbackComponent} from './core/auth/callback/callback.component';
import {LoginAlertComponent} from './login-alert/login-alert.component';

const appRoutes: Routes = [
  {path: 'dashboard', component: DashboardComponent},
  {path: 'testing', loadChildren: 'src/app/testing/testing.module#TestingModule'},
  {path: 'callback', component: CallbackComponent},
  // {path: '', redirectTo: '/transactions/dockets', pathMatch: 'full'},
  {path: 'transactions', loadChildren: 'src/app/transactions/transactions.module#TransactionsModule'},
  {path: 'finance', loadChildren: 'src/app/finance/finance.module#FinanceModule'},
  {path: 'inventory', loadChildren: 'src/app/inventory/inventory.module#InventoryModule'},
  {path: 'production', loadChildren: 'src/app/production/production.module#ProductionModule'},
  {path: 'orders', loadChildren: 'src/app/orders/orders.module#OrdersModule'},
  {path: 'services', loadChildren: 'src/app/services/services.module#ServicesModule'},
  {path: 'logistics', loadChildren: 'src/app/logistics/logistics.module#LogisticsModule'},
  {path: 'admin', loadChildren: 'src/app/admin/admin.module#AdminModule'},
  {path: 'products', loadChildren: 'src/app/products/products.module#ProductsModule'},
  {path: 'customers', loadChildren: 'src/app/customers/customers.module#CustomersModule'},
  {path: 'reports', loadChildren: 'src/app/reports/reports.module#ReportsModule'},
  {path: 'reports-old', loadChildren: 'src/app/reports-old/reports.module#ReportsModule'},
  {path: 'interaction', loadChildren: 'src/app/interaction/interaction.module#InteractionModule'},
  {path: 'templates', loadChildren: 'src/app/templates/templates.module#TemplatesModule'},
  // {path: 'callback', component: CallbackComponent},
  // {path: '', redirectTo: 'loginalert', pathMatch: 'full'},
  // {path: '', component: PageNotFoundComponent},  // Wildcard route for a 404 page
  // {path: 'loginalert', component: LoginAlertComponent},
  {path: 'login', component: LoginAlertComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'},
];

@NgModule({
  declarations: [
    // InvoicePreviewComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule,
    // InvoicePreviewComponent
  ]
})
export class AppRoutingModule {
}
