import {StorageAreas} from './storage-area.model';
import {Supplier} from './suppliers.model';

export class IncomingDeliveryList {
  // id: number;
  supplier_id: number;
  supplier: Supplier;
  supplier_reference: string;
  internal_reference: string;
  delivery_date: string;
  storage_area_id: number;
  storage_area: StorageAreas;
  note: string;
  is_complete: boolean;

  constructor(
    // id: number,
    supplier_id: number,
    supplier_reference: string,
    internal_reference: string,
    delivery_date: string,
    storage_area_id: number,
    note: string,
    is_complete: boolean) {
    // this.id = id;
    this.supplier_id = supplier_id;
    this.supplier_reference = supplier_reference;
    this.internal_reference = internal_reference;
    this.delivery_date = delivery_date;
    this.storage_area_id = storage_area_id;
    this.note = note;
    this.is_complete = is_complete;
  }
}
