import {Component, OnInit} from '@angular/core';
import {BsDropdownConfig, BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {faCoffee} from '@fortawesome/free-solid-svg-icons';
import {AuthService} from './core/auth/auth.service';
import {ValueTableLookupService} from './core/services/value-table-lookup.service';
import {CacheService} from './core/services/cache.service';
import {AppService} from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: {isAnimated: true, autoClose: true}
    }
  ]
})
export class AppComponent implements OnInit {
  title = 'VirtualVoucher';
  faCoffee = faCoffee;
  quotes: any = [];
  number = 0;

  constructor(
    public auth: AuthService,
    private lookupS: ValueTableLookupService,
    private cacheS: CacheService,
    private appS: AppService
  ) {
    this.developerQuotes();
    auth.handleAuthentication();
    // this.cacheS.getCustomers();
    // this.cacheS.getProducts();
  }

  ngOnInit() {
    // console.log('%c Oh my heavens! ', 'background: #222; color: #bada55');
  }

  developerQuotes() {
    // Get random number for quote array
    this.number = Math.floor(Math.random() * (324));
    this.appS.getQuotes().subscribe(data => {
      this.quotes = data;
      // console.log(this.quotes);
      console.log('%c"' + this.quotes[this.number].text + '" - ' + this.quotes[this.number].from, 'background: #222; color: #FFC300  ');
    });
  }
}
