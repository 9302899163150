import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  // flaskApiService = new BackendConfigurationService();
  // postgrestApiService = new SalestrackApiConfService();

  subscription: Subscription;

  getLookupHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token')
      }),
      observe: 'response' as 'body'
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Range-Unit': 'items',
        'Prefer': 'count=exact'
      }),
      observe: 'response' as 'body'
    };
  }

  getAuthHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      })
    };
  }

  // Http Options for Stored Procedure calls
  getSpHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'params=single-object'
      }),
      observe: 'response' as 'body'
    };
  }

  getHttpOptionsForCSVExports() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'params=single-object'
      }),
      responseType: 'text' as 'json',
    };
  }

  getInsertHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Prefer': 'count=exact, return=headers-only'
      }),
      observe: 'response' as 'body'
    };
  }

  getServerAddress() {
    // return this.postgrestApiService.getServerAddress();
    return 'https://hh-api-dev.srv.handheld.ie/vv_api/';
    // return 'https://hh-api-mst.srv.handheld.ie/vv_api/';
  }

  // todo: Replace these endpoints
  getOldHttpOptions() {
    this.getHttpOptions();
  }

  getOldServerAddress() {
    this.getServerAddress();
    // return 'https://hh-api-dev.srv.handheld.ie/vv_api/';
    // return 'https://hh-api-mst.srv.handheld.ie/v1/';
    // return 'https://hh-api-mst.srv.handheld.ie/v1/';
    // return this.flaskApiService.getServerAddress();
  }
}
