import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.css']
})
export class QrCodeGeneratorComponent implements OnInit {

  item = [{
    'name': 'Agatha Harkness',
    'played by': 'Kathryn Hahn',
    'Fictional universe': 'Marvel Universe',
    'Creator': 'Stan Lee'
  }];

  values = 'QR code string value';
  level: 'L' | 'M' | 'Q' | 'H';
  width = 530;

  qrInfo = JSON.stringify(this.item);

  qr_id_number = '';

  constructor() { }

  ngOnInit() {
    this.level = 'L';
  }

  qrLevel(val: 'L' | 'M' | 'Q' | 'H') {
    this.level = val;
  }

  qrData(val: string) {
    this.values = val;
  }

  qrWidth(val: number) {
    this.width = val;
  }

  setID() {

    const possible = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const lengthOfCode = 512;

    this.qr_id_number = this.makeRandom(lengthOfCode, possible);

  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

}
