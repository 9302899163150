import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TableItemModel } from '../../models/table-item.model';
import { BsDaterangepickerConfig, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FilterModel } from '../../models/filter-model';
import { FilterInputModel } from '../../models/filter-input-model';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-single-selection-table',
  templateUrl: './single-selection-table.component.html',
  styleUrls: ['./single-selection-table.component.css']
})
export class SingleSelectionTableComponent implements OnInit {
  faCalendar = faCalendar;
  tableItems = [];
  baseData: any[];
  filteredData = [];
  selectedItems = [];
  currentFilters: FilterModel[];
  filters = [];
  fromDate = '';
  toDate = '';
  hasDateFilter = false;
  dateFiltersGreaterThen;
  dateFilterLessThen;
  dateRange = false;
  bsConfigRange: Partial<BsDaterangepickerConfig> = Object.assign({}, { containerClass: 'theme-dark-blue'});
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, { containerClass: 'theme-dark-blue'});
  @Input() set _tableItems (tableItems: TableItemModel[]) {
    this.tableItems = tableItems;
  }
  @Input() set _filters (filters: FilterInputModel[]) {
    this.filters = filters;
  } // will be made
  @Input() set _baseData(baseData: any[]) {
    this.baseData = baseData;
    this.filteredData = this.baseData.slice(0);
  }
  @Input() set _hasDateFilter (hasDateFilter: boolean) {
    this.hasDateFilter = hasDateFilter;
  }
  @Input() set _dateRange (dateRange: boolean) {
    this.dateRange = dateRange;
  }
  @Input() set _dateFiltersGreaterThen (dateFiltersGreaterThen: string) {
    this.dateFiltersGreaterThen = dateFiltersGreaterThen;
  } // what you want the the data filter to be based on e.g transaction of effective from
  @Input() set _dateFilterLessThen (dateFilterLessThen: string) {
    this.dateFilterLessThen = dateFilterLessThen;
  }
  @Output() newSelectedItem = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    const filterList = [];
    for (const filter of this.filters) {
      if (filter.numberValue) {
        filterList.push(new FilterModel(filter.name, filter.varName, null, filter.numberValue,
        filter.multipleObject, filter.multipleObjectValue));
      } else {
        filterList.push(new FilterModel(filter.name, filter.varName, '',
        filter.numberValue, filter.multipleObject, filter.multipleObjectValue));
      }
    }
    this.currentFilters = filterList;
  }

  applyFilters() {
    let filtering = this.baseData.slice(0);
    for (const filter of this.currentFilters) {
      if (filter.multipleObject) {
        if (filter.isItANumberValue) {
        if (filter.userInput !== null && filter.userInput !== '') {
          filtering = filtering.filter(filterItem =>
          Number(filterItem[filter.varName][filter.multipleObjectValue]) === Number(filter.userInput));
        }
      } else {
        if (filter.userInput !== '') {
          filtering = filtering.filter(filterItem => filterItem[filter.varName][filter.multipleObjectValue].includes(filter.userInput));
        }
      }
      } else {
        if (filter.isItANumberValue) {
        if (filter.userInput !== null && filter.userInput !== '') {
          filtering = filtering.filter(filterItem => Number(filterItem[filter.varName]) === Number(filter.userInput));
        }
      } else {
        if (filter.userInput !== '') {
          filtering = filtering.filter(filterItem => filterItem[filter.varName].includes(filter.userInput));
        }
      }
      }
    }
    if (this.fromDate !== '' && this.toDate !== '' && this.dateRange) {
      filtering = filtering.filter(item => (new Date(this.fromDate).getTime() <= new Date(item[this.dateFilterLessThen]).getTime()) &&
      (new Date(this.toDate).getTime() >= new Date(item[this.dateFiltersGreaterThen]).getTime()));
    } else if (this.fromDate !== '' && this.toDate !== '') {
      // uses fromDate and datefilterGreater then if single date
      filtering = filtering.filter(item => Date.parse(this.fromDate) === Date.parse(item[this.dateFiltersGreaterThen]));
    }
    this.filteredData = filtering;
  }

  clearFilters() {
    this.filteredData = this.baseData.slice(0);
    this.currentFilters.forEach(filter => {
      filter.userInput = (filter.isItANumberValue) ? null : '';
    });
  }

  sortTableFromIt(columnToUse) {
    this.filteredData.sort((a, b) => a[columnToUse] < b[columnToUse] ? -1 : 1 );
    this.baseData.sort((a, b) => a[columnToUse] < b[columnToUse] ? -1 : 1 );
  }

  selectItem(item) {
    console.log(item);
      if (this.selectedItems[0] === item) {
        this.selectedItems = [];
        this.newSelectedItem.emit(null);
      } else {
        this.selectedItems = [item];
        this.newSelectedItem.emit(item);
      }
  }

  applyDates(dateRange) {
    this.fromDate = dateRange[0];
    this.toDate = dateRange[1];
    this.applyFilters();
  }

  applyOneDate(theDate) {
     this.fromDate = theDate;
     this.toDate = theDate;
     this.applyFilters();
   }

   alreadySelected(tableItem) {
    const myIndex = this.selectedItems.findIndex(si => si.id === tableItem.id);
    if (myIndex !== -1) {
      return ['#53A451', 'white'];
    } else {
      return ['', ''];
    }
  }

}
