import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  missingDates = 'No dates selected';
  missingName = 'Missing Name';
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  private configSuccess: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    panelClass: ['snackbar-success'],
  };

  private configError: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    panelClass: ['snackbar-error'],
  };

  private configWarn: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    panelClass: ['snackbar-warning'],
  };

  /** Bootstrap */

  private messageSource = new Subject<string>();
  messageSource$ = this.messageSource.asObservable();

  /** Depreciated */
  private errorMessageSource = new Subject<string>();
  errorMessageSource$ = this.errorMessageSource.asObservable();

  constructor(
    private _snackBar: MatSnackBar,
    private logger: NGXLogger
  ) {
  }

  snackbarSuccess(message, action?) {
    this._snackBar.open(message, 'close', this.configSuccess);
  }

  snackbarError(message, action?) {
    this._snackBar.open(message, 'close', this.configError);
  }

  alert(message: string) {
    const messageSource = new Subject<string>();
    this.messageSource.next(message);
    this._snackBar.open(message, 'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000
    });
  }

  /** Bootstrap */

  message(msg: string, type?) {
    this.logger.log('Type', type);
    if (type === undefined) {
      type = 'danger';
    }
    const messageSource = new Subject<string>();
    if (msg === 'dates') {
      console.log('No date selected');
    }
    messageSource.next(msg);
    // @ts-ignore
    this.messageSource.next({msg, type});
  }

  /** Depreciated */

  errorMessage(msg: string, type?) {
    this.logger.log('Type', type);
    if (type === undefined) {
      type = 'danger';
    }
    const errorMessageSource = new Subject<string>();
    if (msg === 'dates') {
      console.log('No date selected');
    }
    errorMessageSource.next(msg);
    // @ts-ignore
    this.errorMessageSource.next({msg, type});
  }
}
