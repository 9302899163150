import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as auth0 from 'auth0-js';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GetApiReply} from '../models/get-api-reply.model';
import {BackendConfigurationService} from '../services/backend-configuration.service';
import {SalestrackApiConfService} from '../services/salestrackServiceV2/salestrackApi-conf.service';
import {catchError, pluck, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {ConfigService} from '../services/config.service';

(window as any).global = window;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: NGXLogger,
    private conf: ConfigService,
    private backendS: BackendConfigurationService,
    private backendV2S: SalestrackApiConfService,
    private http: HttpClient
  ) {
    let ourUrl: any = window.location.href;
    ourUrl = ourUrl.split('/');
    const redirectUri = ourUrl[0] + '//' + ourUrl[2] + '/callback';
    this.auth0 = new auth0.WebAuth({
      // clientID: 'RMoSkFQrF5Y3FMBeKZ8kKNG2rGHoYYew', // TinCan
      clientID: 'yqit5a3sVJFh0N548B3tY4Aiwe0tiJ9P', // VirtualVoucher
      domain: 'handheld.eu.auth0.com',
      responseType: 'token id_token',
      audience: 'https://SalestrackAPI',
      // audience: 'https://VirtualVoucherAPI',
      redirectUri: redirectUri,
      scope: 'openid email user_metadata'
    });
  }
  auth0: any;
  userInfo = [];

  // Handles setting the token expiry time and storing it in the local storage for presentance.
  private static setSession(authResult): void {
    console.log('Auth Result', authResult);
    // this.logger.log('Auth Result', authResult);
    // Set the time that the Access token will expire at.
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    const httpOptions = this.conf.getHttpOptions();
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        // console.log(authResult);
        AuthService.setSession(authResult);
        this.router.navigate(['/login']);
      } else if (err) {
        this.router.navigate(['/login']);
        console.log(err);
      }
    });
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the Access.
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }

  login() {
    localStorage.clear();
    this.logger.debug('this.route', this.route);
    this.auth0.authorize();
  }

  // Cleans out any token information.
  logout() {
    localStorage.clear();
    // Removes data from local storage.
    // localStorage.removeItem('access_token');
    // localStorage.removeItem('id_token');
    // localStorage.removeItem('expires_at');
    // // Redirect the user to the home page
    // localStorage.removeItem('currencies');
    // localStorage.removeItem('customerTypes');
    // localStorage.removeItem('depots');
    // localStorage.removeItem('frequency');
    // localStorage.removeItem('masterAccounts');
    // localStorage.removeItem('products');
    // localStorage.removeItem('routes');
    this.router.navigate(['/login']);
  }

  /*** Permissions ***/

  getUserInfo() {
    return this.http.get<GetApiReply>(
      `https://handheld.eu.auth0.com/userinfo`,
      this.conf.getAuthHttpOptions()
    );
  }

  getUsers(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}users?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getUserRoles(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}user_roles?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getRoles(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}roles?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

}
