import {Injectable} from '@angular/core';
import {GetApiReply} from '../../../models/get-api-reply.model';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SalestrackApiConfService} from '../salestrackApi-conf.service';
import {PostPutDelApiReply} from '../../../models/post-put-delete-api-reply.model';

@Injectable()
export class OrganisationsServiceV2 {
  constructor(
    private http: HttpClient,
    private configService: SalestrackApiConfService
  ) {
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // Replace with logging service later.
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token')
      }),
      observe: 'response' as 'body'
    };
  }

  getMyOrgId() {
    const httpOptions = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.configService.getServerAddress()}/rpc/get_organisation`,
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      }),
    );
  }

  getOrganisations(optionalParams = '') {
    const httpOptions = this.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.configService.getServerAddress()}organisations?${optionalParams}`,
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return {
          // @ts-ignore
          data: res.body,
          // @ts-ignore
          headers: res.headers,
        };
      }),
    );
  }
  updateOrganisations(optionalParams = '', item) {
    const httpOptions = this.getHttpOptions();
    return this.http.patch<GetApiReply>(
      `${this.configService.getServerAddress()}organisations?${optionalParams}`,
      item,
      // @ts-ignore
      httpOptions
    );
  }
}
