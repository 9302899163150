export class FilterModel {
  name: string;
  varName: any;
  userInput: any;
  isItANumberValue: boolean;
  multipleObject: boolean;
  multipleObjectValue: string;

  constructor(name: string, varName: any, userInput: any, isItANumberValue: boolean,
              multipleObject: boolean, multipleObjectValue: string) {
    this.name = name;
    this.varName = varName;
    this.isItANumberValue = isItANumberValue;
    this.userInput = userInput;
    this.multipleObject = multipleObject;
    this.multipleObjectValue = multipleObjectValue;
  }
}
