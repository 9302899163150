import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivationButtonsComponent} from './utils/activation-buttons/activation-buttons.component';
import {DateSelectionComponent} from './utils/date-selection/date-selection.component';
import {DateRangeSelectionComponent} from './utils/date-range-selection/date-range-selection.component';
import {RestFilteringInputComponent} from './utils/rest-filtering-input/rest-filtering-input.component';
import {ViewAllElementsComponent} from './utils/rest-filtering-input/view-all-elements/view-all-elements.component';
import {LoadingComponent} from './utils/loading/loading.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {RouterModule} from '@angular/router';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {AuthService} from './auth/auth.service';
import {EditFormComponent} from './utils/edit-form/edit-form.component';
import {CreateFormComponent} from './utils/create-form/create-form.component';
import {MultipleSelectionTableComponent} from './utils/multiple-selection-table/multiple-selection-table.component';
import {ErrorMessagesComponent} from './utils/error-messages/error-messages.component';
import {DeleteListComponent} from './utils/delete-list/delete-list.component';
import {BestBeforeDateRangeSelectionComponent} from './utils/best-before-date-range-selection/best-before-date-range-selection.component';
import {RestTableComponent} from './utils/rest-table/rest-table.component';
import {SingleSelectionTableComponent} from './utils/single-selection-table/single-selection-table.component';
import {DisplayTableWithRemovableItemComponent} from './utils/display-table-with-removable-item/display-table-with-removable-item.component';
import {FooterComponent} from './utils/footer/footer.component';
import {FilteringInputComponent} from './utils/filtering-input/filtering-input.component';
import {MaterialDateRangeSelectionComponent} from './utils/material-date-range-selection/material-date-range-selection.component';
import {MatDateRangeSelectionComponent} from './utils/mat-date-range-selection/mat-date-range-selection.component';
import {
    _MatMenuDirectivesModule,
    MatButtonModule, MatCardModule,
    MatDatepickerModule, MatDialogModule, MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule, MatListModule, MatMenuModule,
    MatOptionModule, MatProgressBarModule,
    MatSelectModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {BarcodeListComponent} from './utils/barcode-list/barcode-list.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import {BarcodeViewerComponent} from './utils/barcode-viewer/barcode-viewer.component';
import {MaterialRestFilteringInputComponent} from './utils/material-rest-filtering-input/material-rest-filtering-input.component';
import {TooltipComponent} from './utils/tooltip/tooltip.component';
import {PageTitleComponent} from './parts/page-title/page-title.component';
import {MatDialogComponent} from './utils/mat-dialog/mat-dialog.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import { QrCodeGeneratorComponent } from './utils/qr-code-generator/qr-code-generator.component';
import {QRCodeModule} from 'angular2-qrcode';
import { QrCodeScannerComponent } from './utils/qr-code-scanner/qr-code-scanner.component';
import { DialogComponent } from './utils/dialog/dialog.component';
import { AlertsComponent } from './utils/alerts/alerts.component';
import { NavbarComponent } from './parts/navbar/navbar.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import { MaterialDropdownComponent } from './parts/material-dropdown/material-dropdown.component';
import {MenuItemComponent} from './parts/navbar/menu-item/menu-item.component';

@NgModule({
  declarations: [
    // TODO: Alphabetically sort declarations
    // NavigationBarComponent,
    ActivationButtonsComponent,
    BestBeforeDateRangeSelectionComponent,
    BarcodeListComponent,
    BarcodeViewerComponent,
    CreateFormComponent,
    DeleteListComponent,
    DisplayTableWithRemovableItemComponent,
    DateSelectionComponent,
    DateRangeSelectionComponent,
    EditFormComponent,
    ErrorMessagesComponent,
    FilteringInputComponent,
    FooterComponent,
    LoadingComponent,
    MultipleSelectionTableComponent,
    RestFilteringInputComponent,
    RestTableComponent,
    SingleSelectionTableComponent,
    ViewAllElementsComponent,
    MaterialDateRangeSelectionComponent,
    MatDateRangeSelectionComponent,
    MaterialRestFilteringInputComponent,
    TooltipComponent,
    PageTitleComponent,
    MatDialogComponent,
    QrCodeGeneratorComponent,
    QrCodeScannerComponent,
    DialogComponent,
    AlertsComponent,
    NavbarComponent,
    MaterialDropdownComponent,
    MenuItemComponent,
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        // TODO: Add NgxBarcodeModule
        // NgxBarcodeModule,
        PaginationModule,
        NgMultiSelectDropDownModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgxBarcodeModule,
        MatOptionModule,
        MatSelectModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatDialogModule,
        AlertModule,
        QRCodeModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        CollapseModule,
        _MatMenuDirectivesModule,
        MatMenuModule,
        MatToolbarModule,
    ],
  providers: [AuthService],

    exports: [
        // NavigationBarComponent,
        ActivationButtonsComponent,
        BestBeforeDateRangeSelectionComponent,
        CreateFormComponent,
        DateSelectionComponent,
        DateRangeSelectionComponent,
        DeleteListComponent,
        EditFormComponent,
        ErrorMessagesComponent,
        LoadingComponent,
        MultipleSelectionTableComponent,
        RestFilteringInputComponent,
        RestTableComponent,
        SingleSelectionTableComponent,
        DisplayTableWithRemovableItemComponent,
        FooterComponent,
        FilteringInputComponent,
        MaterialDateRangeSelectionComponent,
        MatDateRangeSelectionComponent,
        BarcodeListComponent,
        MaterialRestFilteringInputComponent,
        TooltipComponent,
        PageTitleComponent,
        AlertsComponent,
        NavbarComponent,
        MaterialDropdownComponent
    ],
  entryComponents: [DialogComponent],
})
export class CoreModule {
}
