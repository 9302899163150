import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TableItemModel} from '../../models/table-item.model';
import {FilterModel} from '../../models/filter-model';
import {FilterInputModel} from '../../models/filter-input-model';
import {BsDatepickerConfig, BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import PrintJob from 'print-job';
import {BooleanFilter} from '../../models/boolean-filter.model';

@Component({
  selector: 'app-multiple-selection-table',
  templateUrl: './multiple-selection-table.component.html',
  styleUrls: ['./multiple-selection-table.component.css']
})
export class MultipleSelectionTableComponent implements OnInit {
  @ViewChild('listToPrintOne', {static: false, read: ElementRef}) listToPrintOne: ElementRef;
  @ViewChild('listToPrintTwo', {static: false, read: ElementRef}) listToPrintTwo: ElementRef;
  limitSelection = false;
  printable = false;
  hasDateFilter = false;
  dateRange = false;
  nameOnLabels;
  dateFiltersGreaterThen;
  dateFilterLessThen;
  tableItems = [];
  filters = [];
  topButtons = [];
  bottomButtons = [];
  fromDate = '';
  toDate = '';
  printing = false;
  booleanFilters = [];
  baseData: any[];
  filteredData = [];
  selectedItems = [];
  showCrud = true;
  emitObject = false; // it will set wheater the table emits the object again if already selected or a null value
  emitSelectAll = false; // this will emit a list of objects on select all if set to through.
  currentFilters: FilterModel[];
  bsConfigRange: Partial<BsDaterangepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});

  @Input() set _tableItems(tableItems: TableItemModel[]) {
    this.tableItems = tableItems;
  }

  @Input() set _topButtons(topButtons: string []) {
    this.topButtons = topButtons;
  }

  @Input() set _bottomButtons(bottomButtons: string []) {
    this.bottomButtons = bottomButtons;
  }

  @Input() set _limitSelection(limitSelection: boolean) {
    this.limitSelection = limitSelection;
  }

  @Input() set _filters(filters: FilterInputModel[]) {
    this.filters = filters;
  } // will be made
  @Input() set _baseData(baseData: any[]) {
    this.baseData = baseData;
    this.filteredData = this.baseData.slice(0);
  }

  @Input() set _printable(printable: boolean) {
    this.printable = printable;
  }

  @Input() set _hasDateFilter(hasDateFilter: boolean) {
    this.hasDateFilter = hasDateFilter;
  }

  @Input() set _dateRange(dateRange: boolean) {
    this.dateRange = dateRange;
  }

  // If you filter from a date range or one date
  @Input() set _dateFiltersGreaterThen(dateFiltersGreaterThen: string) {
    this.dateFiltersGreaterThen = dateFiltersGreaterThen;
  } // what you want the the data filter to be based on e.g transaction of effective from
  @Input() set _dateFilterLessThen(dateFilterLessThen: string) {
    this.dateFilterLessThen = dateFilterLessThen;
  }

  @Input() set _nameOnLabels(nameOnLabels: string) {
    this.nameOnLabels = nameOnLabels;
  } // Sets the update, edit and delete label name like create customer
  @Input() set _emitObject(val: boolean) {
    this.emitObject = val;
  }

  @Input() set _emitSelectAllList(val: boolean) {
    this.emitSelectAll = val;
  }

  @Input() set _booleanFilters(filters: BooleanFilter[]) {
    this.booleanFilters = filters.slice(0);
  }

  @Input() set _crudOptions(crudOn: boolean) {
    this.showCrud = crudOn;
  }

  @Input() preSelect = null;
  // Sets the update, edit and delete label name like create customer
  @Output() itemChosen = new EventEmitter<any>();
  @Output() deleteItems = new EventEmitter<any>();
  @Output() createItem = new EventEmitter<any>();
  @Output() editItems = new EventEmitter<any>();
  @Output() customButtonEmit = new EventEmitter<any>();
  @Output() newSelectedItem = new EventEmitter<any>();
  @Output() emitListEvent = new EventEmitter<any>();

  // need to output selected Items
  constructor() {
  }

  ngOnInit() {
    console.log(this.tableItems);
    const filterList = [];
    for (const filter of this.filters) {
      if (filter.numberValue) {
        filterList.push(new FilterModel(filter.name, filter.varName, null, filter.numberValue,
          filter.multipleObject, filter.multipleObjectValue));
      } else {
        filterList.push(new FilterModel(filter.name, filter.varName, '',
          filter.numberValue, filter.multipleObject, filter.multipleObjectValue));
      }
    }
    console.log(this.fromDate);
    console.log(this.toDate);
    this.currentFilters = filterList;
    if (this.preSelect) {
      this.selectedItems[0] = this.preSelect;
    }
  }

  clearFilters() {
    this.filteredData = this.baseData.slice(0);
    this.currentFilters.forEach(filter => {
      filter.userInput = (filter.isItANumberValue) ? null : '';
    });
  }

  selectAll() {
    this.selectedItems = this.filteredData.slice(0);
    this.emitListEvent.emit(this.filteredData.slice(0));
  }

  unSelectAll() {
    this.selectedItems.splice(0, this.selectedItems.length);
    this.emitListEvent.emit([]);
  }

  selectItem(item) {
    if (this.limitSelection) {
      if (this.selectedItems[0] === item) {
        this.selectedItems = [];
        if (this.emitObject) {
          this.newSelectedItem.emit(item);
        } else {
          this.newSelectedItem.emit(null);
        }
      } else {
        this.selectedItems = [item];
        this.newSelectedItem.emit(item);
      }
    } else {
      const indexOfSelectedItem = this.selectedItems.findIndex(alreadySelectedItem => alreadySelectedItem.id === item.id);
      if (indexOfSelectedItem === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(indexOfSelectedItem, 1);
      }
      this.newSelectedItem.emit(item);
    }
  }

  // This method loops through the table taking the list of filters
  // and checking the user Inputs aginst the values. within the table
  applyFilters() {
    let filtering = this.baseData.slice(0);
    for (const filter of this.currentFilters) {
      if (filter.multipleObject) {
        if (filter.isItANumberValue) {
          if (filter.userInput !== null && filter.userInput !== '') {
            filtering = filtering.filter(filterItem =>
              Number(filterItem[filter.varName][filter.multipleObjectValue]) === Number(filter.userInput));
          }
        } else {
          if (filter.userInput !== '') {
            filtering = filtering.filter(filterItem => filterItem[filter.varName][filter.multipleObjectValue].includes(filter.userInput));
          }
        }
      } else {
        if (filter.isItANumberValue) {
          if (filter.userInput !== null && filter.userInput !== '') {
            filtering = filtering.filter(filterItem => Number(filterItem[filter.varName]) === Number(filter.userInput));
          }
        } else {
          if (filter.userInput !== '') {
            filtering = filtering.filter(filterItem => filterItem[filter.varName].includes(filter.userInput));
          }
        }
      }
    }
    for (const filter of this.booleanFilters) {
      console.log(filter);
      filtering = filtering.filter(item => item[filter.varName] === filter.boolValue);
    }
    if (this.fromDate !== '' && this.toDate !== '' && this.dateRange) {
      filtering = filtering.filter(item => (new Date(this.fromDate).getTime() <= new Date(item[this.dateFilterLessThen]).getTime()) &&
        (new Date(this.toDate).getTime() >= new Date(item[this.dateFiltersGreaterThen]).getTime()));
    } else if (this.fromDate !== '' && this.toDate !== '') {
      // uses fromDate and datefilterGreater then if single date
      filtering = filtering.filter(item => Date.parse(this.fromDate) === Date.parse(item[this.dateFiltersGreaterThen]));
    }
    this.filteredData = filtering;
  }

  onCreate() {
    this.selectedItems = [];
    this.createItem.emit(true);
  }

  onEdit() {
    this.editItems.emit(this.selectedItems);
    this.selectedItems = [];
  }

  onDelete() {
    this.deleteItems.emit(this.selectedItems);
    this.selectedItems = [];
  }

  alreadySelected(tableItem) {
    const myIndex = this.selectedItems.findIndex(si => si.id === tableItem.id);
    if (myIndex !== -1) {
      return ['#53A451', 'white'];
    } else {
      return ['', ''];
    }
  }

  applyDates(dateRange) {
    this.fromDate = dateRange[0];
    this.toDate = dateRange[1];
    this.applyFilters();
  }

  applyOneDate(theDate) {
    this.fromDate = theDate;
    this.toDate = theDate;
    this.applyFilters();
  }

  buttonEmit(name) {
    this.customButtonEmit.emit({name: name, list: [...this.selectedItems]});
    this.selectedItems = [];
  }

  activateCrudApps() {
    if (this.selectedItems.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  sortTableFromIt(item) {
    console.log(item);
    if (item.multipleObject) {
      this.filteredData.sort(
        (a, b) => a[item.valuesToAccess][item.multipleObjectValue] < b[item.valuesToAccess][item.multipleObjectValue] ? -1 : 1
      );
      this.baseData.sort(
        (a, b) => a[item.valuesToAccess][item.multipleObjectValue] < b[item.valuesToAccess][item.multipleObjectValue] ? -1 : 1
      );
    } else {
      this.filteredData.sort(
        (a, b) => a[item.valuesToAccess] < b[item.valuesToAccess] ? -1 : 1
      );
      this.baseData.sort(
        (a, b) => a[item.valuesToAccess] < b[item.valuesToAccess] ? -1 : 1
      );
    }
    // console.log(this.filteredData[0][columnToUse]);
    // this.filteredData.sort((a, b) => a[columnToUse] < b[columnToUse] ? -1 : 1 );
    // this.baseData.sort((a, b) => a[columnToUse] < b[columnToUse] ? -1 : 1 );
  }

  printList() {
    this.printing = true;
    setTimeout(() => PrintJob.print(this.listToPrintTwo.nativeElement), 1000);
    setTimeout(() => this.printing = false, 1000);
  }
}
