import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputCreateFormItem} from '../../models/input-create-from-item.model';
import {DropdownCreateFormItem} from '../../models/dropdown-create-form-item.model';
import {ActivationCreateFormItem} from '../../models/activation-create-form-item.model';
import {DateCreateFormItem} from '../../models/date-create-form-item.model';
import {BsDatepickerConfig, BsDaterangepickerConfig} from 'ngx-bootstrap/datepicker';
import {ErrorMessageService} from '../error-messages/error-message.service';
import {DateRangeCreateFormItem} from '../../models/date-range-create-form-item.model';

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent implements OnInit {
  title: string;
  inputFormItems: InputCreateFormItem[] = [];
  inputFormDropdownItems: DropdownCreateFormItem[] = [];
  inputFormActivationButtons: ActivationCreateFormItem[];
  inputDateFormItems: DateCreateFormItem[];
  inputDateRangeFormItems: DateRangeCreateFormItem[] = [];
  originalInputFormItems: InputCreateFormItem[] = [];
  originalInputFormDropdownItems: DropdownCreateFormItem[] = [];
  originalInputFormActivationButtons: ActivationCreateFormItem[] = [];
  originalInputDateFormItems: DateCreateFormItem[] = [];
  @Input() _tooltip = '';

  @Input() set _title(title: string) {
    this.title = title;
  }

  @Input() set _inputFormItems(inputFormItems: InputCreateFormItem[]) {
    this.inputFormItems = inputFormItems.slice(0);
    this.originalInputFormItems = [...inputFormItems];
  }

  @Input() set _inputFormDropdownItems(inputFormDropdownItems: DropdownCreateFormItem[]) {
    this.inputFormDropdownItems = inputFormDropdownItems.slice(0);
  }

  @Input() set _inputFormActivationButtons(inputFormActivationButtons: ActivationCreateFormItem[]) {
    this.inputFormActivationButtons = inputFormActivationButtons.slice(0);
    console.log(this.inputFormActivationButtons);
  }

  @Input() set _inputDateFormItems(inputDateFormItems: DateCreateFormItem[]) {
    this.inputDateFormItems = inputDateFormItems.slice(0);
  }

  @Input() set _inputDateRangeFormItems(inputDateRangeFormItems: DateRangeCreateFormItem[]) {
    this.inputDateRangeFormItems = inputDateRangeFormItems.slice(0);
  }

  @Output() createdObject = new EventEmitter<any>();
  errors = [];
  bsConfig: Partial<BsDatepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});
  bsRangeConfig: Partial<BsDaterangepickerConfig> = Object.assign({}, {containerClass: 'theme-dark-blue'});

  constructor(private errorS: ErrorMessageService) {
  }

  ngOnInit() {
  }

  validSubmit() {
    let itIsValid = true;
    let indexOfForms = 0;
    const inputIndexErrors = [];
    for (const it of this.inputFormItems.filter(ifi => ifi.required)) {
      if (it.userInput === null || it.userInput === '') {
        inputIndexErrors.push(indexOfForms);
        itIsValid = false;
      }
      indexOfForms++;
    }
    let dropErrorIndex = 0;
    const dropDownErrors = [];
    for (const it of this.inputFormDropdownItems.filter(ifi => ifi.required)) {
      if (it.itIsAForeignKey && it.id === null && it.userChoice.id === null) {
        dropDownErrors.push(dropErrorIndex);
        itIsValid = false;
      }
      dropErrorIndex++;
    }
    if (!itIsValid) {
      for (const i of inputIndexErrors) {
        this.errorS.newErrorMessage(`${this.inputFormItems.filter(ifi => ifi.required)[i].heading} is not valid`);
      }
      for (const i of dropDownErrors) {
        this.errorS.newErrorMessage(`${this.inputFormDropdownItems.filter(ifi => ifi.required)[i].heading} is not valid`);
      }
    }
    return itIsValid;
  }

  setUserChoice($event, i) {
    this.inputFormDropdownItems[i].userChoice = $event;
  }

  applyDates(theDate, i) {
    this.inputDateFormItems[i].dateValue = new Date(new Date(theDate).toISOString().split('T')[0]).toISOString();
  }

  applyDateRange(theDates, i) {
    this.inputDateRangeFormItems[i].startValueToAccess = new Date(new Date(theDates[0]).toISOString().split('T')[0]).toISOString();
    this.inputDateRangeFormItems[i].endDateValue = new Date(new Date(theDates[1]).toISOString().split('T')[0]).toISOString();
  }

  submit() {
    // for (const it of this.inputFormDropdownItems) {
    //   const listIndex = it.list.findIndex( x => x[it.listAccess] === it.userInput);
    //   if (listIndex !== -1) {
    //     it.userChoice = it.list[listIndex];
    //   }
    // }
    console.log(this.inputFormDropdownItems);
    const newItem = {};
    for (const it of this.inputFormItems) {
      if (it.numberValue) {
        if (!it.userInput) {
          newItem[it.valuesToAccess] = 0;
        } else {
          newItem[it.valuesToAccess] = it.userInput;
        }
      } else {
        newItem[it.valuesToAccess] = it.userInput;
      }
    }
    for (const it of this.inputFormDropdownItems) {
      if (it.itIsAForeignKey) {
        if (it.userChoice !== null) {
          newItem[it.valueToAccess + '_id'] = it.userChoice.id;
        } else {
          newItem[it.valueToAccess + '_id'] = null;
        }
      } else {
        newItem[it.valueToAccess] = it.userChoice;
      }
    }
    for (const it of this.inputFormActivationButtons) {
      newItem[it.valuesToAccess] = it.buttonActive;
    }
    for (const it of this.inputDateFormItems) {
      newItem[it.valuesToAccess] = it.dateValue;
    }
    for (const it of this.inputDateRangeFormItems) {
      newItem[it.startValueToAccess] = it.startingDateValue;
      newItem[it.endValueToAccess] = it.endDateValue;
    }
    console.log(newItem);
    if (this.validSubmit()) {
      this.createdObject.emit(newItem);
    }
  }
}
