import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './config.service';
import {GetApiReply} from '../models/get-api-reply.model';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  getOrganisations(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}organisations?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getMyOrgId() {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}/rpc/get_organisation`,
      this.conf.getLookupHttpOptions()
    );
  }

  updateOrganisations(optionalParams = '', item) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}organisations?${optionalParams}`,
      item,
      this.conf.getHttpOptions()
    );
  }

  /** Logo */

  getLogos(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}logos?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getLogo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('id_token'),
        'Accept': 'application/octet-stream'
      }),
      responseType: 'text'
    };
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}logos?select=bytea_value&limit=1`,
      // this.conf.getHttpOptions()
      // @ts-ignore
      httpOptions
    ).pipe(
      tap(res => {
        return res;
      }),
    );
  }

  createLogo(logo) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}logos`,
      logo,
      this.conf.getHttpOptions()
    );
  }

  updateLogo(optionalParams, logo) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}logos?${optionalParams}`,
      logo,
      this.conf.getHttpOptions()
    );
  }
}
