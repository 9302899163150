import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'app-material-dropdown',
  templateUrl: './material-dropdown.component.html',
  styleUrls: ['./material-dropdown.component.css']
})
export class MaterialDropdownComponent implements OnInit {
  @Input() items = [];
  @Input() selectedItem = '';
  @Input() target = '';
  @Input() label = '';
  @Output() button: EventEmitter<any> = new EventEmitter();
  @Output() selection = new EventEmitter();
  btnCondition = true;

  constructor(
    private logger: NGXLogger,
    ) {}

  ngOnInit() {
    this.logger.log('selectedItem', this.target);
    if (this.target === '') {
      this.logger.log('selectedItem', this.selectedItem);
    } else {
      const target = this.target;
      this.logger.log('selectedItem', this.selectedItem[target]);
    }
  }

  itemSelected(item) {
    this.logger.log('selectedItem', this.target);
    if (this.target === '') {
      this.logger.log('selectedItem', this.selectedItem);
      this.logger.log(`Setting state to ${item}`);
    } else {
      const target = this.target;
      this.logger.log('selectedItem', this.selectedItem[target]);
      this.logger.log(`Setting state to ${item[target]}`);
    }
    this.selection.emit(item);
  }
}
