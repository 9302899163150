import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {version} from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public version: string = version;
  year = moment(new Date()).format('YYYY');

  constructor() {
  }

  ngOnInit() {
  }

}
