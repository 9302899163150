import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {GetApiReply} from '../models/get-api-reply.model';
import {Observable, of} from 'rxjs';
import {catchError, pluck, tap} from 'rxjs/operators';
import {PostPutDelApiReply} from '../models/post-put-delete-api-reply.model';
import {StandingOrderAmendmentLookUp} from '../models/dbModels/Lookups/standing-order-amendment-lookup.model';
import {StandingOrderLookUp} from '../models/dbModels/Lookups/standing-orders-lookup.model';
import {ProductionProductLookups} from '../models/dbModels/Lookups/production-products-lookups.model';
import {ProductionLookup} from '../models/dbModels/Lookups/production-lookup.model';
import {ProductionListItemsLookup} from '../models/dbModels/Lookups/production-list-items-lookup.model';
import {ConfigService} from './config.service';


@Injectable()
export class ProductionService {
  standingOrders = [];
  products = [];
  customers = [];
  standingOrderCache = 0;
  productionItems = [];
  productionListId = null;
  productionListState = false;
  productionListName = '';

  constructor(
    private http: HttpClient,
    private conf: ConfigService
  ) {
  }

  /** Contents
   * ********
   * Batches
   * Production Lists
   */

  /** Batches */
  getBatches(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}batches?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getBatchProducts(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}batch_products?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createBatchProducts(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}batch_products`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  createBatchNotes(new_item) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}batch_notes`,
      new_item,
      httpOptions
    );
  }

  createBatch(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}batches`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateBatch(updated_item, id) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}batches?id=eq.${id}`,
      updated_item,
      httpOptions
    );
  }

  updateBatchProduct(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}batch_products?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  // Ingredients

  getIngredients(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}products?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  // Formulas

  getFormulas(optionalParams) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}recipes?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createFormula(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}recipes`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateFormula(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}recipes?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  /** Production groups */

  // Production Groups

  getProductionGroups(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_groups?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createProductionGroup(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}production_groups`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateProductionGroup(pGroup, id) {
    return this.http.patch<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_groups?id=eq.${id}`,
      pGroup,
      this.conf.getHttpOptions()
    );
  }

  deleteProductionGroups(ids) {
    const httpOption = this.conf.getHttpOptions();
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_groups?id=[${ids}]`,
      httpOption
    );
  }

  // Production Group products

  createProductionGroupProducts(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}production_group_products`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  updateProductionGroupProduct(pGroup, id) {
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_group_products?id=eq.${id}`,
      pGroup,
      this.conf.getHttpOptions()
    );
  }

  deleteProductionGroupProduct(id) {
    id = id.filter(function (item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}production_group_products?id=in.(${id})`,
      this.conf.getHttpOptions()
    );
  }

  deleteByProductionGroup(production_group_id) {
    production_group_id = production_group_id.filter(function (item) {
      return !isNaN(item);
    });
    return this.http.delete<GetApiReply>(
      `${this.conf.getServerAddress()}production_group_products?production_group_id=in.(${production_group_id})`,
      this.conf.getHttpOptions()
    );
  }

  // Production group schedules

  getProductionGroupSchedules(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_group_schedules?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createProductionSchedule(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}production_group_schedules`,
      new_item,
      this.conf.getHttpOptions()
    );
  }

  /** Production lists */

  // Production Lists
  getProductionLists(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_lists?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  createProductionLists(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}production_lists`,
      new_item,
      this.conf.getInsertHeaders()
    );
  }

  updateProductionLists(updated_item, id) {
    return this.http.patch<GetApiReply>(
      `${this.conf.getServerAddress()}production_lists?id=eq.${id}`,
      updated_item,
      this.conf.getHttpOptions()
    );
  }

  createProductionListItem(new_item) {
    return this.http.post<GetApiReply>(
      `${this.conf.getServerAddress()}production_list_items`,
      new_item,
      this.conf.getInsertHeaders()
    );
  }

  // Production Lists
  getDocketWaste(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}docket_returns_waste?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  // Production Lists
  getProductionWaste(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_list_waste?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  /** -------- *
   * Deprecated *
   * -------- */

  setProductionListId(id) {
    this.productionListId = id;
  }

  getProductionListId() {
    return this.productionListId;
  }

  getProductionListState() {
    return this.productionListState;
  }

  setProductionListState(state) {
    this.productionListState = state;
  }

// End of Look up section
// _______________________________________________________________

// Standing Order Section

// End of Standing order Section
// _______________________________________________________________

// Standing Order Amendment Section

// End of Standing order amendments Section
// _______________________________________________________________

// Production Products Section
  getProductionProducts() {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_products`,
      httpOptions
    );
  }

  createProductionProduct(productionProduct) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_products`,
      productionProduct,
      httpOptions
    );
  }

  deleteProductionProduct(id) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_products/${id}`,
      httpOptions
    );
  }

// End of Production Products Section
// _______________________________________________________________
// Production List Section

  getProductionListItems(optionalParams = '') {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_list_items?${optionalParams}`,
      this.conf.getHttpOptions()
    );
  }

  getProductionGroupData(filters) {
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}products?${filters}`,
      // TODO: Should use production list endpoint?
      this.conf.getHttpOptions()
    );
  }

  massUpdateProductionGroups(ids, updateSettings) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.put<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}products?id=[${ids}]&$return_data`,
      updateSettings,
      httpOptions
    );
  }

  createProductionListItems(listItems) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_list_items`,
      listItems,
      httpOptions
    );
  }

  deleteProductionListItems(listItems) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_list_items?id=[${listItems}]`,
      httpOptions
    );
  }

  deleteProductionListItemsByProductionList(id) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_list_items?production_list_id=${id}`,
      httpOptions
    );
  }

  getProductionListsOld() {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production_lists`,
      httpOptions
    );
  }

  createProductionList(productionList) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_lists`,
      productionList,
      httpOptions
    );
  }

  deleteProductionList(productionLists) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.delete<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production_lists?id=[${productionLists}]`,
      httpOptions
    );
  }

// Look Ups

  generateProductionList(productionDate) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.post<PostPutDelApiReply>(
      `${this.conf.getServerAddress()}production/generate_lists`,
      productionDate,
      httpOptions
    );
  }

  getProductionListSubCategoryReport(id) {
    const httpOptions = this.conf.getHttpOptions();
    return this.http.get<GetApiReply>(
      `${this.conf.getServerAddress()}production/sub_category_report/${id}`,
      httpOptions
    );
  }

  /*******************
   *
   * Production Groups
   *
   *******************/
}
