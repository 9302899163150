import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AuthService} from '../auth/auth.service';
import {faCoffee, faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {AdminService} from '../../admin/admin.service';
import {FaConfig, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {OrganisationsServiceV2} from '../services/salestrackServiceV2/crud-endpoints/organisations.service';
import {UserPermissionController} from '../controllers/userPermissionsController';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
  providers: [AdminService, FaConfig, FaIconLibrary, OrganisationsServiceV2, UserPermissionController]
})
export class NavigationBarComponent implements OnInit {
  organisationEmail;
  base64textString = '';
  faSignIn = faSignInAlt;
  faSignOut = faSignOutAlt;
  preferencesId = null;
  loaded = false;
  modulesList: Array<any>;
  enteredButton = false;
  menuOpen = false;
  subMenuOpen = false;
  // isMatMenuOpen = false;
  // isMatMenu2Open = false;
  prevButtonTrigger;

  /*** User Data ***/

  id;
  name;
  email;
  username;
  tokenData = {
    email: '',
    username: ''
  };
  userData = null;

  // userData = [];

  /*** Roles ***/
    // Process
  roleTransactions = false;
  roleOrders = false;
  roleProduction = false;
  roleInventory = false;
  roleLogistics = false;
  roleFinance = false;
  // Assignment
  roleCustomers = true;
  roleProducts = true;
  // Settings
  roleAdmin = true;
  roleInteraction = false;
  // Performance
  roleReports = true;
  roleDashboard = true;

  /** Links */
  productionReports = '/reports/production-reports';

  constructor(
    public auth: AuthService,
    // public navS: MainNavigationService
    private adminS: AdminService,
    private ren: Renderer2,
    private organisationsServiceV2: OrganisationsServiceV2,
    private userPermissionsController: UserPermissionController
  ) {
  }

  ngOnInit() {
    // console.log('Navigation Bar Array');
    // console.log(this.navigationList);
    // console.log('----------------------------');
    this.loadNavData();
  }

  loadNavData() {
    if (this.auth.isAuthenticated()) {
      this.getData();
      this.getLogo();
      this.getUserData();
    } else {
      setTimeout(() => this.loadNavData(), 1000);
    }
  }

  getData() {
    this.organisationsServiceV2.getMyOrgId().subscribe(res => {
      // @ts-ignore
      const id = res.body;
      let optionalParams = 'select=*';
      optionalParams += '&id=eq.' + id;
      this.organisationsServiceV2.getOrganisations(optionalParams).subscribe(orgRes => {
        // @ts-ignore
        const item = orgRes.body[0];
        this.id = item.id;
        this.name = item.name;
        this.email = item.email;
      }, orgError => {
        console.error(orgError);
      });
    }, error => {
      console.error(error);
    });
  }

  getLogo() {
    this.adminS.getItems('logos').subscribe((res: any) => {
        // console.log(res); // Hiding navigation array
        if (res.data.length > 0) {
          this.base64textString = `data:image/png;base64,${res.data[0].bytea_value}`;
          // console.log(this.base64textString); // Hiding logo console output
          this.preferencesId = res.data[0].id;
          this.loaded = true;
        } else {
          this.loaded = true;
        }
      },
      error => {
        console.log('error');
      }
    );
  }

  getUserData() {
    this.auth.getUserInfo().subscribe(res => {
      // Get users email
      // @ts-ignore
      this.tokenData.email = res.email;
      // Get username
      this.tokenData.username = res['https://api.hhsalestrack.com/user_metadata'].username;
      this.getUserPerms();
      this.getUserPermsNew();
    }, error => {
      console.log(error);
    });
  }

  getUserPerms() {
    // Get the email id from the users table.
    // Get the user_roles data by joining the table by the user id and the user_roles user_id (This is a FK in the db)
    // In the joined user_roles table do another joing to get the roles data (Another FK in the db)
    let optionalParams = 'select=email,id,user_roles(id,roles(name))';
    optionalParams += '&name=eq.' + this.tokenData.username;
    // Get user data
    this.auth.getUsers(optionalParams).subscribe(res => {
      // @ts-ignore
      this.userData = res;
      // console.log(this.userData);
      this.setPermissions();
    }, error => {
      console.log(error);
    });
  }

  async getUserPermsNew() {
    const userPermissions = await this.userPermissionsController.getUsers(null, null, [this.tokenData.username]);
    console.log('New User Permissions:');
    console.log(userPermissions);
  }

  setPermissions() {
    // Check if the user has the permission by filtering the list of permissions by the role name.
    // Best way to do it since we can't guarantee ids will the consistent across the dbs
    this.roleTransactions = (this.userData[0].user_roles.filter(a => a.roles.name === 'transactions').length === 1);
    this.roleOrders = (this.userData[0].user_roles.filter(a => a.roles.name === 'orders').length === 1);
    this.roleProduction = (this.userData[0].user_roles.filter(a => a.roles.name === 'production').length === 1);
    this.roleInventory = (this.userData[0].user_roles.filter(a => a.roles.name === 'inventory').length === 1);
    this.roleLogistics = (this.userData[0].user_roles.filter(a => a.roles.name === 'logistics').length === 1);
    this.roleFinance = (this.userData[0].user_roles.filter(a => a.roles.name === 'finance').length === 1);
    // Assignment
    this.roleCustomers = true;
    this.roleProducts = true;
    // Settings
    this.roleAdmin = true;
    this.roleInteraction = false;
    // Performance
    this.roleReports = true;
    this.roleDashboard = true;
  }

  dropdown() {
  }

  menuHover() {
    this.menuOpen = true;
    if (this.subMenuOpen) {
      this.subMenuOpen = false;
    }
  }

  menuLeave(trigger) {
    setTimeout(() => {
      if (!this.subMenuOpen && !this.enteredButton) {
        this.menuOpen = false;
        trigger.closeMenu();
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.menuOpen = false;
      }
    }, 80);
  }

  subMenuHover() {
    this.subMenuOpen = true;
  }

  subMenuLeave(trigger1, trigger2) {
    setTimeout(() => {
      if (this.subMenuOpen) {
        trigger1.closeMenu();
        this.menuOpen = false;
        this.subMenuOpen = false;
        this.enteredButton = false;
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.subMenuOpen = false;
        trigger2.closeMenu();
      }
    }, 100);
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger !== trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.menuOpen = false;
        this.subMenuOpen = false;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else if (!this.menuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-focused');
        this.ren.removeClass(trigger.menu.items.first['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger) {
    setTimeout(() => {
      if (this.enteredButton && !this.menuOpen) {
        trigger.closeMenu();
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      }

      if (!this.menuOpen) {
        trigger.closeMenu();
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-focused');
        // this.ren.removeClass(button['_elementRef'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }
}
